export const markers = {
  total_cholesterol: {
    category: "Heart Function",
    name: "Total Cholesterol",
    long_name: "Heart Function",
    uom: "mg/dl",
    description:
      "Total Cholesterol is a measure of the total amount of cholesterol in your blood. It includes both low-density lipoprotein (LDL) cholesterol and high-density lipoprotein (HDL) cholesterol.  It's important to remember that elevated cholesterol doesn\\u2019t mean heart attack.  In fact, only half of people suffering from heart attacks have elevated cholesterol.  It is simply part of the bigger picture. Cholesterol is important for the body to manufacture hormones, vitamin D, bile acids, and help maintain the structure of your cells. ",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 100,
        border_min: 100,
        normal_min: 100,
        normal_max: 200,
        border_max: 239,
        test_max: 400,
        range_text: "Normal range 100 - 200 mg/dl",
        low_risk_text:
          "Total cholesterol levels less than 200 milligrams per deciliter (mg/dL) are considered desirable for adults. ",
        low_borderline_text:
          "Total cholesterol levels less than 200 milligrams per deciliter (mg/dL) are considered desirable for adults.",
        normal_text:
          "Your total cholesterol levels are within the optimal range! Total cholesterol levels less than 200 milligrams per deciliter (mg/dL) are considered desirable for adults. Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! A reading between 200 and 239 mg/dL is considered borderline high. This may be due to a number of reasons such as:\n - Cardiovascular disease\n - Atherosclerosis\n - Insulin resistance\n - Diabetes\n - Metabolic syndrome\n - Hyperlipoproteinemia\n - Fatty liver\n - Hypothyroidism\n - Pancreatitis\n - Biliary stasis\n - Poor metabolism and utilization of fats\nThe elevated levels of cholesterol may put you at risk for heart diseases. \nWe suggest you speak to a healthcare professional about how to start to manage this and then track every 3 months to ensure it is going down.  Please also look at your TG:HDL ratio as it is the most powerful indicator of future heart disease.",
        high_risk_text:
          "You're at risk! A reading of 240 mg/dL and above is considered high. This may be due to a number of reasons such as:\n\n - Cardiovascular disease\n - Atherosclerosis\n - Insulin resistance\n - Diabetes\n - Metabolic syndrome\n - Hyperlipoproteinemia\n - Fatty liver\n - Hypothyroidism\n - Pancreatitis\n - Biliary stasis\n - Poor metabolism and utilization of fats\n\nThe elevated levels of cholesterol may put you at risk for heart diseases. \nYou need to speak with an MD immediately to find a way to manage this. Please keep tracking to ensure your levels are returning to normal.",
      },
      female: {
        test_min: 100,
        border_min: 100,
        normal_min: 100,
        normal_max: 200,
        border_max: 239,
        test_max: 400,
        range_text: "Normal range 100 - 200 mg/dl",
        low_risk_text:
          "Total cholesterol levels less than 200 milligrams per deciliter (mg/dL) are considered desirable for adults. ",
        low_borderline_text:
          "Total cholesterol levels less than 200 milligrams per deciliter (mg/dL) are considered desirable for adults.",
        normal_text:
          "Your total cholesterol levels are within the optimal range! Total cholesterol levels less than 200 milligrams per deciliter (mg/dL) are considered desirable for adults. Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! A reading between 200 and 239 mg/dL is considered borderline high. This may be due to a number of reasons such as:\n - Cardiovascular disease\n - Atherosclerosis\n - Insulin resistance\n - Diabetes\n - Metabolic syndrome\n - Hyperlipoproteinemia\n - Fatty liver\n - Hypothyroidism\n - Pancreatitis\n - Biliary stasis\n - Poor metabolism and utilization of fats\nThe elevated levels of cholesterol may put you at risk for heart diseases. \nWe suggest you speak to a healthcare professional about how to start to manage this and then track every 3 months to ensure it is going down.  Please also look at your TG:HDL ratio as it is the most powerful indicator of future heart disease.",
        high_risk_text:
          "You're at risk! A reading of 240 mg/dL and above is considered high. This may be due to a number of reasons such as:\n\n - Cardiovascular disease\n - Atherosclerosis\n - Insulin resistance\n - Diabetes\n - Metabolic syndrome\n - Hyperlipoproteinemia\n - Fatty liver\n - Hypothyroidism\n - Pancreatitis\n - Biliary stasis\n - Poor metabolism and utilization of fats\n\nThe elevated levels of cholesterol may put you at risk for heart diseases. \nYou need to speak with an MD immediately to find a way to manage this. Please keep tracking to ensure your levels are returning to normal.",
      },
    },
  },
  hdl: {
    category: "Heart Function",
    name: "HDL",
    long_name: "Heart Function",
    uom: "mg/dl",
    description:
      'HDL stands for high-density lipoproteins. It is sometimes called the "good" cholesterol because it carries cholesterol from other parts of your body back to your liver. Your liver then removes the cholesterol from your body.\n\nLifestyle changes known to increase HDL, such as moving more, quitting smoking or improving your diet, have been shown to lower the risk of heart attacks, However, medications that specifically increase HDL levels have failed to reduce the rate of heart attacks.',
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 25,
        border_min: 40,
        normal_min: 60,
        normal_max: 100,
        border_max: 100,
        test_max: 100,
        range_text: "Normal range 60 - 100 mg/dl",
        low_risk_text:
          "You are at risk! Your HDL levels of lower than 40 mg/dL may put you at risk of cardiovascular disorder. \n\nThis may be due to a number of reasons such as:\n\n - Lack of exercises/sedentary lifestyle\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Oxidative stress\n - Chemical/metal toxicity\n\nYou need to speak with an MD immediately to find a way to manage this. Please keep tracking to ensure your levels are increasing.",
        low_borderline_text:
          "Attention! Your HDL levels of lower than 40 mg/dL may put you at risk of cardiovascular disorder. \nThe HDL-C or the good cholesterol in your blood is below the ideal levels. Good thing, there are measures you can do to improve this such as: decreasing intake of highly fatty foods and adding food items with healthy fats in the diet, like avocado, nuts and oily fish.  You can also limit sedentary activities (such as prolong sitting) and increase movements either by walking, jogging or any appropriate exercise. \nDecreased HDL levels may be due to a number of reasons such as:\n\n - Lack of exercises/sedentary lifestyle\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Oxidative stress\n - Chemical/metal toxicity\n\nWe suggest you speak to a healthcare professional about how to start to manage this and then track every 3 months to ensure it is going up.",
        normal_text:
          "Your HDL levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Your HDL levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_risk_text:
          "Your HDL levels are within the optimal range! Keep using Lab Me to track progress over time.",
      },
      female: {
        test_min: 25,
        border_min: 50,
        normal_min: 50,
        normal_max: 100,
        border_max: 100,
        test_max: 100,
        range_text: "Normal range 50 - 100 mg/dl",
        low_risk_text:
          "You are at risk! Your HDL levels of lower than 25 mg/dL may put you at risk of cardiovascular disorder. \n\nThis may be due to a number of reasons such as:\n\n - Lack of exercises/sedentary lifestyle\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Hyperthyroidism\n - Oxidative stress\n - Chemical/metal toxicity\n\nYou need to speak with an MD immediately to find a way to manage this. Please keep tracking to ensure your levels are increasing.",
        low_borderline_text:
          "Attention! Your HDL levels of lower than 50 mg/dL may put you at risk of cardiovascular disorder. \nThe HDL-C or the good cholesterol in your blood is below the ideal levels. Good thing, there are measures you can do to improve this such as: decreasing intake of highly fatty foods and adding food items with healthy fats in the diet, like avocado, nuts and oily fish.  You can also limit sedentary activities (such as prolong sitting) and increase movements either by walking, jogging or any appropriate exercise. \nDecreased HDL levels may be due to a number of reasons such as:\n\n - Lack of exercises/sedentary lifestyle\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Hyperthyroidism\n - Oxidative stress\n - Chemical/metal toxicity\n\nWe suggest you speak to a healthcare professional about how to start to manage this and then track every 3 months to ensure it is going up.",
        normal_text:
          "Your HDL levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Your HDL levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_risk_text:
          "Your HDL levels are within the optimal range! Keep using Lab Me to track progress over time.",
      },
    },
  },
  chol_hdl_ratio: {
    category: "Heart Function",
    name: "Chol/HDL Ratio",
    long_name: "Heart Function",
    uom: "",
    description:
      "What’s in a ratio?\nYour cholesterol ratio is calculated by dividing your total cholesterol by your HDL number. For instance, if your total cholesterol is 180 and your HDL is 82, your cholesterol ratio is 2.2. According to the American Heart Association (AHA), you should aim to keep your ratio below 5, with the ideal cholesterol ratio being 3.5. Read about the effects of high cholesterol here.\n\nRatio and risk for men\nAccording to the Framingham Heart Study, a cholesterol ratio of 5 indicates average risk of heart disease for men. Men have double the risk for heart disease if their ratio reaches 9.6, and they have roughly half the average risk for heart disease with a cholesterol ratio of 3.4. \nRatio and risk for women. Because women often have higher levels of good cholesterol, their cholesterol ratio risk categories differ. According to the same study, a 4.4 ratio indicates average risk for heart disease in women. Heart disease risk for women doubles if their ratio is 7, while a ratio of 3.3 signifies roughly half the average risk.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 0,
        normal_min: 0,
        normal_max: 3.5,
        border_max: 33,
        test_max: 33,
        range_text: "Normal range 0 - 3.5 ",
        low_risk_text: "Your cholesterol ratio is within the normal limits.",
        low_borderline_text:
          "Your cholesterol ratio is within the normal limits.",
        normal_text:
          "Your cholesterol ratio is within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your cholesterol ratio indicates that either your total cholesterol level is elevated or your HDL level is decreased or a combination of both. This may put you at risk of developing heart disease. Please contact your healthcare practitioner to find the cause of elevated cholesterol ratio. You can also manage your cholesterol and HDL levels by maintaining proper diet with decreased consumption of saturated fats, trans fats, and refined carbohydrates as well as healthy weight and recommended amount of physical activity. ",
        high_risk_text:
          "You are at risk! Your cholesterol ratio indicates that either your total cholesterol level is elevated or your HDL level is decreased or a combination of both. This may put you at risk of developing heart disease. Please contact your MD immediately to find the cause of elevated cholesterol ratio. You can also manage your cholesterol and HDL levels by maintaining proper diet with decreased consumption of saturated fats, trans fats, and refined carbohydrates as well as maintaining healthy weight and recommended amount of physical activity. ",
      },
      female: {
        test_min: 0,
        border_min: 0,
        normal_min: 0,
        normal_max: 3.5,
        border_max: 33,
        test_max: 33,
        range_text: "Normal range 0 - 3.5 ",
        low_risk_text: "Your cholesterol ratio is within the normal limits.",
        low_borderline_text:
          "Your cholesterol ratio is within the normal limits.",
        normal_text:
          "Your cholesterol ratio is within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your cholesterol ratio indicates that either your total cholesterol level is elevated or your HDL level is decreased or a combination of both. This may put you at risk of developing heart disease. Please contact your healthcare practitioner to find the cause of elevated cholesterol ratio. You can also manage your cholesterol and HDL levels by maintaining proper diet with decreased consumption of saturated fats, trans fats, and refined carbohydrates as well as healthy weight and recommended amount of physical activity. ",
        high_risk_text:
          "You are at risk! Your cholesterol ratio indicates that either your total cholesterol level is elevated or your HDL level is decreased or a combination of both. This may put you at risk of developing heart disease. Please contact your MD immediately to find the cause of elevated cholesterol ratio. You can also manage your cholesterol and HDL levels by maintaining proper diet with decreased consumption of saturated fats, trans fats, and refined carbohydrates as well as maintaining healthy weight and recommended amount of physical activity. ",
      },
    },
  },
  ldl: {
    category: "Heart Function",
    name: "LDL",
    long_name: "Heart Function",
    uom: "mg/dl",
    description:
      "LDL stands for low-density lipoprotein, a type of cholesterol found in your body. LDL is often referred to as bad cholesterol. This is because too much LDL results in a build-up of cholesterol in your arteries, which can lead to heart attacks and strokes.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 12,
        border_min: 12,
        normal_min: 12,
        normal_max: 130,
        border_max: 159,
        test_max: 700,
        range_text: "Normal range 12 - 130 mg/dl",
        low_risk_text:
          "Your LDL levels are withing normal limits!\n\nPlease keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Your LDL levels are withing normal limits!\n\nPlease keep using Lab Me to monitor your progress over time.",
        normal_text:
          "Your LDL levels are too high if you have coronary artery disease present, and your LDL levels are nearly optimal if you do not have coronary artery disease.\n\nIf you have coronary artery disease and your LDL levels are slightly elevated, this could be due to a number of reasons such as\n\n - Diet high in refined carbohydrates\n - Atherosclerosis\n - Metabolic syndrome\n - Fatty liver\n - Hyperlipidemia\n\nYou need to consult with your healthcare provider to find a way to manage this. Also, you can lower your LDL cholesterol by decreasing the amount of saturated and trans fats as well as refined carbohydrates in your diet. Please keep tracking your progress over time to ensure your levels are decreasing.",
        high_borderline_text:
          "You are at high risk if you have coronary artery disease present, and your risk is elevated if you do not have coronary artery disease present.\n\nThis could be due to a number of reasons such as\n\n - Diet high in refined carbohydrates\n - Atherosclerosis\n - Metabolic syndrome\n - Fatty liver\n - Hyperlipidemia\n\nYou need to consult with your healthcare provider to find a way to manage this. Please keep tracking to ensure your levels are decreasing.",
        high_risk_text:
          "You are at high risk even if you do not have coronary artery disease present, and very high risk if there is coronary artery disease present. If your LDL level is higher than 190 mg/dL, the risk is extremely high. \n\nThis could be due to a number of reasons such as\n\n - Diet high in refined carbohydrates\n - Atherosclerosis\n - Metabolic syndrome\n - Fatty liver\n - Hyperlipidemia\n\nYou need to speak with an MD immediately to find a way to manage this. Please keep tracking to ensure your levels are decreasing.",
      },
      female: {
        test_min: 12,
        border_min: 12,
        normal_min: 12,
        normal_max: 130,
        border_max: 159,
        test_max: 700,
        range_text: "Normal range 12 - 130 mg/dl",
        low_risk_text:
          "Your LDL levels are withing normal limits!\n\nPlease keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Your LDL levels are withing normal limits!\n\nPlease keep using Lab Me to monitor your progress over time.",
        normal_text:
          "Your LDL levels are too high if you have coronary artery disease present, and your LDL levels are nearly optimal if you do not have coronary artery disease.\n\nIf you have coronary artery disease and your LDL levels are slightly elevated, this could be due to a number of reasons such as\n\n - Diet high in refined carbohydrates\n - Atherosclerosis\n - Metabolic syndrome\n - Fatty liver\n - Hyperlipidemia\n\nYou need to consult with your healthcare provider to find a way to manage this. Also, you can lower your LDL cholesterol by decreasing the amount of saturated and trans fats as well as refined carbohydrates in your diet. Please keep tracking your progress over time to ensure your levels are decreasing.",
        high_borderline_text:
          "You are at high risk if you have coronary artery disease present, and your risk is elevated if you do not have coronary artery disease present.\n\nThis could be due to a number of reasons such as\n\n - Diet high in refined carbohydrates\n - Atherosclerosis\n - Metabolic syndrome\n - Fatty liver\n - Hyperlipidemia\n\nYou need to consult with your healthcare provider to find a way to manage this. Please keep tracking to ensure your levels are decreasing.",
        high_risk_text:
          "You are at high risk even if you do not have coronary artery disease present, and very high risk if there is coronary artery disease present. If your LDL level is higher than 190 mg/dL, the risk is extremely high. \n\nThis could be due to a number of reasons such as\n\n - Diet high in refined carbohydrates\n - Atherosclerosis\n - Metabolic syndrome\n - Fatty liver\n - Hyperlipidemia\n\nYou need to speak with an MD immediately to find a way to manage this. Please keep tracking to ensure your levels are decreasing.",
      },
    },
  },
  ldl_hdl_ratio: {
    category: "Heart Function",
    name: "LDL/HDL Ratio",
    long_name: "Heart Function",
    uom: "",
    description:
      "A high serum LDL:HDL ratio can be predictive of sudden cardiac death in middle age men.  It is a good idea to keep this as a baseline over time to give a clearer picture of how your lifestyle is affecting your health.\n\nThe optimal ratio is below 2.5. The ratio that shows moderate risk is between 2.5 and 3.3, and the ratio above 3.3 indicates high risk.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 1,
        border_min: 1,
        normal_min: 1,
        normal_max: 3.29,
        border_max: 3.3,
        test_max: 5,
        range_text: "Normal range 1 - 3.29 ",
        low_risk_text:
          "Your LDH:HDL ratio is within the normal limits. Keep using Lab Me to monitor your health. ",
        low_borderline_text:
          "Your LDH:HDL ratio is within the normal limits. Keep using Lab Me to monitor your health. ",
        normal_text:
          "Your LDH:HDL ratio is within the optimal range! Keep using Lab Me to monitor your health. ",
        high_borderline_text:
          "Attention! Your LDH/HDL ratio is at the borderline. This may be due to the number of reasons:\n\n - Lack of exercises/sedentary lifestyle\n - Diet high in saturated fats/refined carbohydrates\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Oxidative stress\n - Chemical/metal toxicity\n\nGood thing high LDL:HDL can often be managed with proper diet and physical activity. However, we recommend you to contact your healthcare practitioner to rule out more serious causes and build appropriate treatment plan. Keep using Lab Me to monitor your progress over time. ",
        high_risk_text:
          "You are at risk! Your LDH/HDL ratio is significanty elevated, which indicates high risk of cardiovascular disorder. This may be due to the number of reasons:\n\n - Lack of exercises/sedentary lifestyle\n - Diet high in saturated fats/refined carbohydrates\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Oxidative stress\n - Chemical/metal toxicity\n\nGood thing high LDL:HDL can often be managed with proper diet and physical activity. However, we recommend you to contact your MD immediately to rule out more serious causes and build appropriate treatment plan. Keep using Lab Me to monitor your progress over time. ",
      },
      female: {
        test_min: 1,
        border_min: 1,
        normal_min: 1,
        normal_max: 3.29,
        border_max: 3.3,
        test_max: 5,
        range_text: "Normal range 1 - 3.29 ",
        low_risk_text:
          "Your LDH:HDL ratio is within the normal limits. Keep using Lab Me to monitor your health. ",
        low_borderline_text:
          "Your LDH:HDL ratio is within the normal limits. Keep using Lab Me to monitor your health. ",
        normal_text:
          "Your LDH:HDL ratio is within the optimal range! Keep using Lab Me to monitor your health. ",
        high_borderline_text:
          "Attention! Your LDH/HDL ratio is at the borderline. This may be due to the number of reasons:\n\n - Lack of exercises/sedentary lifestyle\n - Diet high in saturated fats/refined carbohydrates\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Oxidative stress\n - Chemical/metal toxicity\n\nGood thing high LDL:HDL can often be managed with proper diet and physical activity. However, we recommend you to contact your healthcare practitioner to rule out more serious causes and build appropriate treatment plan. Keep using Lab Me to monitor your progress over time. ",
        high_risk_text:
          "You are at risk! Your LDH/HDL ratio is significanty elevated, which indicates high risk of cardiovascular disorder. This may be due to the number of reasons:\n\n - Lack of exercises/sedentary lifestyle\n - Diet high in saturated fats/refined carbohydrates\n - Hyperlipidemia\n - Fatty liver\n - Metabolic syndrome\n - Atherosclerosis\n - Oxidative stress\n - Chemical/metal toxicity\n\nGood thing high LDL:HDL can often be managed with proper diet and physical activity. However, we recommend you to contact your MD immediately to rule out more serious causes and build appropriate treatment plan. Keep using Lab Me to monitor your progress over time. ",
      },
    },
  },
  vldl: {
    category: "Heart Function",
    name: "VLDL",
    long_name: "Heart Function",
    uom: "mg/dl",
    description:
      'Very-low-density lipoprotein (VLDL) cholesterol is produced in the liver and released into the bloodstream to supply body tissues with a type of fat (triglycerides). It\'s considered one of the "bad" forms of cholesterol, along with LDL cholesterol and triglycerides. This is because high levels cholesterol can clog your arteries and lead to a heart attack. Sixty percent of a VLDL particle is a triglyceride.\n',
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 10,
        border_min: 10,
        normal_min: 10,
        normal_max: 30,
        border_max: 30,
        test_max: 80,
        range_text: "Normal range 10 - 30 mg/dl",
        low_risk_text: "Your VLDL is within optimal range.",
        low_borderline_text: "Your VLDL is within optimal range.",
        normal_text:
          "Your VLDL levels are within optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your VLDL levels are at the borderline, which may put you at risk for heart attack or stroke. We highly recommend contacting an MD to find out how to keep this managed before it becomes an issue. We also would highly recommend you order a follow-up test in 3 months if you haven’t already subscribed.",
        high_risk_text:
          "Attention! Your VLDL levels are at risk. The higher your VLDL level, the more likely you are to have a heart attack or stroke. Please consult your doctor as soon as possible. We also would highly recommend you order a follow-up test in 3 months if you haven’t already subscribed.",
      },
      female: {
        test_min: 10,
        border_min: 10,
        normal_min: 10,
        normal_max: 30,
        border_max: 30,
        test_max: 80,
        range_text: "Normal range 10 - 30 mg/dl",
        low_risk_text: "Your VLDL is within optimal range.",
        low_borderline_text: "Your VLDL is within optimal range.",
        normal_text:
          "Your VLDL levels are within optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your VLDL levels are at the borderline, which may put you at risk for heart attack or stroke. We highly recommend contacting an MD to find out how to keep this managed before it becomes an issue. We also would highly recommend you order a follow-up test in 3 months if you haven’t already subscribed.",
        high_risk_text:
          "Attention! Your VLDL levels are at risk. The higher your VLDL level, the more likely you are to have a heart attack or stroke. Please consult your doctor as soon as possible. We also would highly recommend you order a follow-up test in 3 months if you haven’t already subscribed.",
      },
    },
  },
  triglycerides: {
    category: "Heart Function",
    name: "Triglycerides",
    long_name: "Heart Function",
    uom: "mg/dl",
    description:
      "Triglycerides are a type of fat (lipid) found in your blood. When you eat, your body converts any calories it doesn't need to use right away into triglycerides. The triglycerides are stored in your fat cells. Later, hormones release triglycerides for energy between meals. If you regularly eat more calories than you burn, particularly from high-carbohydrate foods, you may have high triglycerides (hypertriglyceridemia). Triglycerides and cholesterol are different types of lipids that circulate in your blood:\n\n - Triglycerides store unused calories and provide your body with energy.\n - Cholesterol is used to build cells and certain hormones.\n",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 50,
        border_min: 50,
        normal_min: 50,
        normal_max: 150,
        border_max: 199,
        test_max: 400,
        range_text: "Normal range 50 - 150 mg/dl",
        low_risk_text: "Your triglycerides are within optimal range.",
        low_borderline_text: "Your triglycerides are within optimal range.",
        normal_text:
          "Your triglycerides levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your triglycerides levels are at the borderline. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nPlease contact your MD to find the way to manage this before it becomes an issue. Keep using Lab Me to monitor your progress over time. ",
        high_risk_text:
          "You are at risk! Your triglycerides levels are elevated. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nHigh triglycerides may contribute to hardening of the arteries or thickening of the artery walls (arteriosclerosis) — which increases the risk of stroke, heart attack and heart disease. Extremely high triglycerides can also cause acute inflammation of the pancreas (pancreatitis).\n\nPlease contact your MD immediately to find the way to manage this. Keep using Lab Me to monitor your progress over time. ",
      },
      female: {
        test_min: 50,
        border_min: 50,
        normal_min: 50,
        normal_max: 150,
        border_max: 199,
        test_max: 400,
        range_text: "Normal range 50 - 150 mg/dl",
        low_risk_text: "Your triglycerides are within optimal range.",
        low_borderline_text: "Your triglycerides are within optimal range.",
        normal_text:
          "Your triglycerides levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your triglycerides levels are at the borderline. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nPlease contact your MD to find the way to manage this before it becomes an issue. Keep using Lab Me to monitor your progress over time. ",
        high_risk_text:
          "You are at risk! Your triglycerides levels are elevated. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nHigh triglycerides may contribute to hardening of the arteries or thickening of the artery walls (arteriosclerosis) — which increases the risk of stroke, heart attack and heart disease. Extremely high triglycerides can also cause acute inflammation of the pancreas (pancreatitis).\n\nPlease contact your MD immediately to find the way to manage this. Keep using Lab Me to monitor your progress over time. ",
      },
    },
  },
  triglyceride_hdl_ratio: {
    category: "Heart Function",
    name: "Triglyceride/HDL Ratio",
    long_name: "Heart Function",
    uom: "",
    description:
      "Research has found that elevation in the ratio of TG to HDL-c was the single most powerful predictor of extensive coronary heart disease among all the lipid variables examined.  This ratio is an easy, non-invasive means of predicting the presence and extent of coronary atherosclerosis.\n\nLow TG:HDL is desirable. As long as the TG is not below 40, your ratio can be below 1:1, as it is in many well-trained and properly nourished athletes, for example a TG of 50 mg/dL and HDL of 80 mg/dL provides a low TG:HDL ratio of 0.6. High TG:HDL, especially >3, indicates significant risk of heart attack and stroke.\n\nThis calculation is useful in predicting heart disease. \n",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 0,
        normal_min: 0,
        normal_max: 2.99,
        border_max: 3,
        test_max: 10,
        range_text: "Normal range 0 - 2.99 ",
        low_risk_text: "Your TG:HDL ratio is within optimal range.",
        low_borderline_text: "Your TG:HDL ratio is within optimal range.",
        normal_text:
          "Your TG:HDL ratio is within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your TG:HDL ratio is at the borderline. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nif it is going up then you are developing a high risk for heart disease and stroke!  We highly recommend contacting your MD to find out how to keep this managed before it becomes an issue.  We also would highly recommend you order a follow up test in 3 months if you haven’t already subscribed.",
        high_risk_text:
          "You are at risk! Your TG:HDL ratio is elevated. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nHigh TG:HDL, especially >3, indicates a significant risk of heart attack and stroke. Research has found that, elevation in the ratio of TG to HDL-c was the single most powerful predictor of extensive coronary heart disease among all the lipid variables examined. Please speak with your MD immediattly about a solution to this. Keep using Lab Me to track your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 0,
        normal_min: 0,
        normal_max: 2.99,
        border_max: 3,
        test_max: 10,
        range_text: "Normal range 0 - 2.99 ",
        low_risk_text: "Your TG:HDL ratio is within optimal range.",
        low_borderline_text: "Your TG:HDL ratio is within optimal range.",
        normal_text:
          "Your TG:HDL ratio is within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your TG:HDL ratio is at the borderline. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nif it is going up then you are developing a high risk for heart disease and stroke!  We highly recommend contacting your MD to find out how to keep this managed before it becomes an issue.  We also would highly recommend you order a follow up test in 3 months if you haven’t already subscribed.",
        high_risk_text:
          "You are at risk! Your TG:HDL ratio is elevated. This may be due to the number of reasons:\n\n- Metabolic Syndrome\n- Fatty liver\n- Liver congestion\n- Insulin resistance\n- Cardiovascular disease\n- Atherosclerosis\n- Poor metabolism and utilization of fats\n- Early stage hyperglycemia/Diabetes\n- Hyperlipidemia/ Hyperlipoproteinemia\n- Primary hypothyroidism\n- Adrenal cortical dysfunction\n- Secondary hypothyroidism - anterior pituitary dysfunction\n• Alcoholism\n\nHigh TG:HDL, especially >3, indicates a significant risk of heart attack and stroke. Research has found that, elevation in the ratio of TG to HDL-c was the single most powerful predictor of extensive coronary heart disease among all the lipid variables examined. Please speak with your MD immediattly about a solution to this. Keep using Lab Me to track your progress over time.",
      },
    },
  },
  hscrp: {
    category: "Heart Risk",
    name: "hsCRP",
    long_name: "Heart Risk",
    uom: "mg/L",
    description:
      "C-reactive protein (CRP) is a protein that increases in the blood with inflammation and infection as well as following a heart attack, surgery, or trauma. Studies have suggested that a persistent low level of inflammation plays a major role in atherosclerosis, the narrowing of blood vessels due to build-up of cholesterol and other lipids, which is often associated with cardiovascular disease (CVD). The hs-CRP test accurately measures low levels of CRP to identify low but persistent levels of inflammation and thus helps predict a person's risk of developing CVD.\n\nMonitoring this regularly can help check for ongoing inflammation, which can indicate infection or a chronic inflammatory disease, such as rheumatoid arthritis or lupus, as well as risk of heart disease.\n",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0.5,
        border_min: 0.5,
        normal_min: 0.5,
        normal_max: 2,
        border_max: 3,
        test_max: 150,
        range_text: "Normal range 0.5 - 2 mg/L",
        low_risk_text:
          "You are at low risk of developing cardiovascular disease if your hs-CRP level is lower than 1.0 mg/L. ",
        low_borderline_text:
          "You are at low risk of developing cardiovascular disease if your hs-CRP level is lower than 1.0 mg/L. ",
        normal_text:
          "You are at low risk of developing cardiovascular disease if your hs-CRP level is lower than 1.0 mg/L. ",
        high_borderline_text:
          "You are at average risk of developing cardiovascular disease if your levels are between 1.0 mg/L and 3.0 mg/L. You are at high risk for cardiovascular disease if your hs-CRP level is higher than 3.0 mg/L.",
        high_risk_text:
          "You are at high risk for cardiovascular disease if your hs-CRP level is higher than 3.0 mg/L.  Please check with your MD immediately to find a way to manage this risk.",
      },
      female: {
        test_min: 0.5,
        border_min: 0.5,
        normal_min: 0.5,
        normal_max: 2,
        border_max: 3,
        test_max: 150,
        range_text: "Normal range 0.5 - 2 mg/L",
        low_risk_text:
          "You are at low risk of developing cardiovascular disease if your hs-CRP level is lower than 1.0 mg/L. ",
        low_borderline_text:
          "You are at low risk of developing cardiovascular disease if your hs-CRP level is lower than 1.0 mg/L. ",
        normal_text:
          "You are at low risk of developing cardiovascular disease if your hs-CRP level is lower than 1.0 mg/L. ",
        high_borderline_text:
          "You are at average risk of developing cardiovascular disease if your levels are between 1.0 mg/L and 3.0 mg/L. You are at high risk for cardiovascular disease if your hs-CRP level is higher than 3.0 mg/L.",
        high_risk_text:
          "You are at high risk for cardiovascular disease if your hs-CRP level is higher than 3.0 mg/L.  Please check with your MD immediately to find a way to manage this risk.",
      },
    },
  },
  creatinine: {
    category: "Kidney Function",
    name: "Creatinine",
    long_name: "Kidney Function",
    uom: "mg/dl",
    description:
      "Creatinine is a waste product produced by muscles from the breakdown of a compound called creatine. Creatinine is removed from the body by the kidneys, which filter almost all of it from the blood and release it into the urine. As the kidneys become impaired for any reason, the creatinine level in the blood will rise due to poor clearance of creatinine by the kidneys. Abnormally high levels of creatinine thus warn of possible malfunction or failure of the kidneys. It is for this reason that standard blood tests routinely check the amount of creatinine in the blood.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0.9,
        border_min: 0.9,
        normal_min: 0.9,
        normal_max: 1.3,
        border_max: 1.6,
        test_max: 10,
        range_text: "Normal range 0.9 - 1.3 mg/dl",
        low_risk_text:
          "Your creatinine levels are within the optimal range! Keep using Lab Me to track progress over time.",
        low_borderline_text:
          "Your creatinine levels are within the optimal range! Keep using Lab Me to track progress over time.",
        normal_text:
          "Your creatinine levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your creatinine levels are borderline.  A few reasons why creatinine levels will begin to rise .  \n\n - Blocked urinary tract (uterine hypertrophy)\n - Kidney problems, such as kidney damage or failure, infection, or reduced blood flow\n - Dehydration \n - Muscle injury, such as breakdown of muscle fibers (rhabdomyolysis)\n - Problems during pregnancy, such as seizures caused by eclampsia or high blood pressure caused by preeclampsia. \n\nPlease consult with your MD to find out what maybe causing your high levels creatinine. Keep using Lab Me to track your progress over time. ",
        high_risk_text:
          "You are at risk! Your creatinine levels are higher than normal. This may be due to:\n\n - Blocked urinary tract (uterine hypertrophy, benign prostatic hyperplasia)\n - Kidney problems, such as kidney damage or failure, infection, or reduced blood flow\n - Loss of body fluids (dehydration)\n - Muscle problems, such as breakdown of muscle fibers (rhabdomyolysis)\n\nPlease consult with your MD immediately to find out what causes your high levels creatinine. Keep using Lab Me to monitor your progress over time. ",
      },
      female: {
        test_min: 0.6,
        border_min: 0.6,
        normal_min: 0.6,
        normal_max: 1.1,
        border_max: 1.3,
        test_max: 10,
        range_text: "Normal range 0.6 - 1.1 mg/dl",
        low_risk_text:
          "Your creatinine levels are within the optimal range! Keep using Lab Me to track progress over time.",
        low_borderline_text:
          "Your creatinine levels are within the optimal range! Keep using Lab Me to track progress over time.",
        normal_text:
          "Your creatinine levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your creatinine levels are borderline.  A few reasons why creatinine levels will begin to rise .  \n\n - Blocked urinary tract (uterine hypertrophy)\n - Kidney problems, such as kidney damage or failure, infection, or reduced blood flow\n - Dehydration \n - Muscle injury, such as breakdown of muscle fibers (rhabdomyolysis)\n - Problems during pregnancy, such as seizures caused by eclampsia or high blood pressure caused by preeclampsia. \n\nPlease consult with your MD to find out what maybe causing your high levels creatinine. Keep using Lab Me to track your progress over time. ",
        high_risk_text:
          "You are at risk! Your creatinine levels are higher than normal. This may be due to:\n\n - Blocked urinary tract (uterine hypertrophy)\n - Kidney problems, such as kidney damage or failure, infection, or reduced blood flow\n - Loss of body fluids (dehydration)\n - Muscle problems, such as breakdown of muscle fibers (rhabdomyolysis)\n - Problems during pregnancy, such as seizures caused by eclampsia or high blood pressure caused by preeclampsia. \n\nPlease consult with your MD immediately to find out what causes your high levels creatinine. Keep using Lab Me to monitor your progress over time. ",
      },
    },
  },
  ggt: {
    category: "Liver Function",
    name: "GGT",
    long_name: "Liver Function",
    uom: "IU/L",
    description:
      "Gamma-glutamyl transferase (GGT) is an enzyme that is found in many organs throughout the body, with the highest concentrations found in the liver. GGT is elevated in the blood in most diseases that cause damage to the liver or bile ducts. Normally, GGT is present in low levels, but when the liver is injured, the GGT level can rise. GGT is usually the first liver enzyme to rise in the blood when any of the bile ducts that carry bile from the liver to the intestines become obstructed, for example, by tumors or stones. This makes it the most sensitive liver enzyme test for detecting bile duct problems. GGT levels are sometimes increased with consumption of even small amounts of alcohol. Higher levels are found more commonly in chronic heavy drinkers than in people who consume less than 2 to 3 drinks per day or who only drink heavily on occasion (binge drinkers). The GGT test may be used in evaluating someone for acute or chronic alcohol abuse.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 10,
        border_min: 10,
        normal_min: 10,
        normal_max: 40,
        border_max: 42,
        test_max: 300,
        range_text: "Normal range 10 - 40 IU/L",
        low_risk_text:
          "A low or normal GGT test result indicates that it is unlikely that you have liver disease or have consumed any alcohol.  Although in somecases it may indicate an issue such as Low gamma-GT (GGT) familial intrahepatic cholestasis - which refers to a spectrum of disease, ranging from mild to severe cases. Also, low GGT levels may be a sign of vitamin B6 and/or Magnesium need. Please consult with your MD for further testing.\n\n",
        low_borderline_text:
          "Your levels are borderline.  It means your liver maybe under some stress. Please continue to monitor and seek professional guidance on how to improve.",
        normal_text:
          "Your GGT levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your GGT levels are borderline.  It means your liver may be under some stress or there is no proper bile flow from the liver to the intestine or you had a small amount of alcohol recently. Please continue using Lab Me to monitor and seek professional guidance to find out what causes this and how to improve. ",
        high_risk_text:
          "You are at risk! Your GGT levels are above the upper normal limit. It may be due to a variety of reasons, such as:\n\n• Dysfunction located outside the liver and inside the biliary tree\n• Biliary obstruction\n• Biliary stasis/insufficiency\n• Liver cell damage (hepatitis, cirrhosis)\n• Alcoholism\n• Acute/chronic Pancreatitis\n• Pancreatic insufficiency\n\nPlease consult with your MD immediately to find what causes this. Keep using Lab Me to monitor your levels of GGT over time to make sure they are decreasing. ",
      },
      female: {
        test_min: 10,
        border_min: 10,
        normal_min: 10,
        normal_max: 72,
        border_max: 75.6,
        test_max: 300,
        range_text: "Normal range 10 - 72 IU/L",
        low_risk_text:
          "A low or normal GGT test result indicates that it is unlikely that you have liver disease or have consumed any alcohol.  Although in somecases it may indicate an issue such as Low gamma-GT (GGT) familial intrahepatic cholestasis - which refers to a spectrum of disease, ranging from mild to severe cases. Also, low GGT levels may be a sign of vitamin B6 and/or Magnesium need. Please consult with your MD for further testing.\n\n",
        low_borderline_text:
          "Your levels are borderline.  It means your liver maybe under some stress. Please continue to monitor and seek professional guidance on how to improve.",
        normal_text:
          "Your GGT levels are within the optimal range! Keep using Lab Me to track progress over time.",
        high_borderline_text:
          "Attention! Your GGT levels are borderline.  It means your liver may be under some stress or there is no proper bile flow from the liver to the intestine or you had a small amount of alcohol recently. Please continue using Lab Me to monitor and seek professional guidance to find out what causes this and how to improve. ",
        high_risk_text:
          "You are at risk! Your GGT levels are above the upper normal limit. It may be due to a variety of reasons, such as:\n\n• Dysfunction located outside the liver and inside the biliary tree\n• Biliary obstruction\n• Biliary stasis/insufficiency\n• Liver cell damage (hepatitis, cirrhosis)\n• Alcoholism\n• Acute/chronic Pancreatitis\n• Pancreatic insufficiency\n\nPlease consult with your MD immediately to find what causes this. Keep using Lab Me to monitor your levels of GGT over time to make sure they are decreasing. ",
      },
    },
  },
  platelets: {
    category: "Metabolic Function",
    name: "PLT",
    long_name: "Metabolic Function",
    uom: "billion/L",
    description:
      "The platelet count is a test that determines the number of platelets in a person's sample of blood. Platelets, also called thrombocytes, are tiny fragments of cells that are essential for normal blood clotting.\n",
    isEnable: true,
    range: {
      age: "all",
      female: {
        test_min: 50,
        border_min: 149.2,
        normal_min: 157,
        normal_max: 317,
        border_max: 332.9,
        test_max: 500,
        range_text: "Normal range 157 - 317 billion/L",
        low_risk_text:
          "You are at risk! Your platelet count is below normal. This can be due to the following reasons:\n\n• Aplastic anemia\n• Vitamin B-12 deficiency\n• Folate deficiency\n• Iron deficiency\n• Viral infections, including HIV, Epstein-Barr, and chickenpox\n• Exposure to chemotherapy, radiation, or toxic chemicals\n• Consuming too much alcohol\n• Cirrhosis\n• Certain types of cancer\n• Hypersplenism, or an enlarged spleen\n• An autoimmune disorder\n• Pregnancy\n• Bacterial infection in the blood\n• Idiopathic thrombocytopenic purpura\n• Thrombotic thrombocytopenic purpura\n• Hemolytic uremic syndrome\n• Disseminated intravascular coagulation\n• Side effects of certain medications - diuretics and anti-seizure medications\n\nPlease consult with your MD immediately to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your platelet count is borderline low. \n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your platelet count is within the normal ranges! Keep using Lab Me to track your progress over time.",
        high_borderline_text:
          "Attention! Your platelet count is borderline high. \n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your platelet count is higher than normal. This may be due to the following reasons: \n\n• Primary thrombocytosis\n• Acute bleeding and blood loss\n• Cancer\n• Infections\n• Iron deficiency\n• Removal of your spleen\n• Hemolytic anemia — a type of anemia in which your body destroys red blood cells faster than it produces them, often due to certain blood diseases or autoimmune disorders\n• Inflammatory disorders, such as rheumatoid arthritis, sarcoidosis or inflammatory bowel disease\n• Surgery or other types of trauma\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
      },
      male: {
        test_min: 50,
        border_min: 128.3,
        normal_min: 135,
        normal_max: 317,
        border_max: 332.9,
        test_max: 500,
        range_text: "Normal range 135 - 317 billion/L",
        low_risk_text:
          "You are at risk! Your platelet count is below normal. This can be caused by one of the following issues:\n\n• Aplastic anemia\n• A vitamin B-12 deficiency\n• A folate deficiency\n• An iron deficiency\n• Viral infections, including HIV, Epstein-Barr virus\n• Exposure to chemotherapy, radiation, or toxic chemicals\n• Consuming too much alcohol\n• Cirrhosis\n• Cancer\n• Immune thrombocytopenia\n• Thrombotic thrombocytopenic purpura\n• Hemolytic uremic syndrome\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your platelet count is borderline low. \n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your platelet count is within the normal ranges! Keep using Lab Me to track your progress over time.",
        high_borderline_text:
          "Attention! Your platelet count is borderline high. \n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your platelet count is higher than normal. This may be due to the following reasons: \n\n• Primary thrombocytosis\n• Acute bleeding and blood loss\n• Cancer\n• Infections\n• Iron deficiency\n• Removal of your spleen\n• Hemolytic anemia — a type of anemia in which your body destroys red blood cells faster than it produces them, often due to certain blood diseases or autoimmune disorders\n• Inflammatory disorders, such as rheumatoid arthritis, sarcoidosis or inflammatory bowel disease\n• Surgery or other types of trauma\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  ferritin: {
    category: "Metabolic Function",
    name: "Ferritin",
    long_name: "Metabolic Function",
    uom: "ng/ml",
    description:
      "A ferritin test measures the amount of ferritin in your blood. Ferritin is a blood protein that contains iron. A ferritin test helps your doctor understand how much iron your body stores.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 12,
        border_min: 28.5,
        normal_min: 30,
        normal_max: 300,
        border_max: 315,
        test_max: 1500,
        range_text: "Normal range 30 - 300 ng/ml",
        low_risk_text:
          "You are at risk! Your blood ferritin level is lower than normal. It indicates your body's iron stores are low and you have an iron deficiency. As a result, you could be anemic. The other conditions that may lead to lower ferritin level include:\n\n• Stomach conditions that affect intestinal absorption\n• Internal bleeding\n\nPlease contact your MD immediately to manage this condition. Keep using Lab Me to track your progress over time. ",
        low_borderline_text:
          "Attention! Your ferritin levels are at the borderline. You may be at risk for developing iron deficiency anemia. Please contact your MD to find the way to manage this. Keep using Lab Me to make sure your ferritin levels increase over time. ",
        normal_text: "Your ferritin levels are within the normal ranges!",
        high_borderline_text:
          "Attention! Your ferritin levels are borderline high. It may be a sign of iron overload or inflammation in your body. Please contact your MD to find out how to normalize your ferritin levels. Keep using Lab Me to monitor your progress over time. ",
        high_risk_text:
          "You are at risk! Your ferritin levels are higher than normal. A higher than normal ferritin level can be seen in several conditions. \n\n• Hemochromatosis\n• Porphyria — A group of disorders caused by an enzyme deficiency that affects your nervous system and skin\n• Rheumatoid arthritis or another chronic inflammatory disorder\n• Liver disease\n• Hyperthyroidism\n• Leukemia\n• Hodgkin's lymphoma\n• Multiple blood transfusions\n• Alcohol abuse\n• Taking too many iron supplements\n\nHowever, the diagnosis of these conditions might require additional testing based on your symptoms and physical examination. If your ferritin level is above normal, your doctor might need to evaluate the results of other tests to determine the next steps.\nPlease contact your MD immediately. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 12,
        border_min: 28.5,
        normal_min: 30,
        normal_max: 200,
        border_max: 210,
        test_max: 1500,
        range_text: "Normal range 30 - 200 ng/ml",
        low_risk_text:
          "You are at risk! Your blood ferritin level is lower than normal. It indicates your body's iron stores are low and you have an iron deficiency. As a result, you could be anemic. The other conditions that may lead to lower ferritin level include:\n\n• Excessive menstrual bleeding\n• Stomach conditions that affect intestinal absorption\n• Internal bleeding\n\nPlease contact your MD immediately to manage this condition. Keep using Lab Me to track your progress over time. ",
        low_borderline_text:
          "Attention! Your ferritin levels are at the borderline. You may be at risk for developing iron deficiency anemia. Please contact your MD to find the way to manage this. Keep using Lab Me to make sure your ferritin levels increase over time.",
        normal_text: "Your ferritin levels are within the normal ranges!",
        high_borderline_text:
          "Attention! Your ferritin levels are borderline high. It may be a sign of iron overload or inflammation in your body. Please contact your MD to find out how to normalize your ferritin levels. Keep using Lab Me to monitor your progress over time. ",
        high_risk_text:
          "You are at risk! Your ferritin levels are higher than normal. A higher than normal ferritin level can be seen in several conditions. \n\n• Hemochromatosis\n• Porphyria — A group of disorders caused by an enzyme deficiency that affects your nervous system and skin\n• Rheumatoid arthritis or another chronic inflammatory disorder\n• Liver disease\n• Hyperthyroidism\n• Leukemia\n• Hodgkin's lymphoma\n• Multiple blood transfusions\n• Alcohol abuse\n• Taking too many iron supplements\n\nHowever, the diagnosis of these conditions might require additional testing based on your symptoms and physical examination. If your ferritin level is above normal, your doctor might need to evaluate the results of other tests to determine the next steps.\nPlease contact your MD immediately. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  glucose: {
    category: "Metabolic Function",
    name: "Glucose",
    long_name: "Metabolic Function",
    uom: "mg/dl",
    description:
      "A blood glucose test is a blood test that screens for diabetes by measuring the level of glucose (sugar) in a person's blood. Normal blood glucose level (while fasting) range within 70 to 99 mg/dL (3.9 to 5.5 mmol/L). Higher ranges could indicate pre-diabetes or diabetes",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 60,
        border_min: 70,
        normal_min: 72,
        normal_max: 100,
        border_max: 180,
        test_max: 400,
        range_text: "Normal range 72 - 100 mg/dl",
        low_risk_text:
          "You are at risk! Your blood glucose levels are lower than normal, which may be life-threatening in case of extremely low values. \n\nPossible causes include\n\n• Reactive hypoglycemia due to diabetes treatment or other conditions\n• Problems mobilizing glycogen from the liver\n• Hyperinsulinism\n• Adrenal hypofunction\n\nPlease contact your MD immediately to manage this condition, which may be life-threatening in case of extremely low values. \nKeep using Lab Me to control your blood glucose and keep it within the normal range. ",
        low_borderline_text:
          "Attention! Your blood glucose is borderline low. This may be caused by a variety of reasons, such as:\n\n• Problems mobilizing glycogen from the liver\n• Overproduction of insulin\n• Adrenal hypofunction\n• Excessive alcohol drinking\n• Chronic kidney disease\n\nAlso, mildly low blood sugar levels are somewhat common for people with diabetes.\n\nPlease consult with your MD to find a way to manage this. Keep using Lab Me to control your blood glucose over time. ",
        normal_text:
          "Your blood glucose is within the normal range! Keep using Lab Me to monitor your progress over time. ",
        high_borderline_text:
          "Attention! Your blood glucose is borderline high. This may be caused by the following:\n\n• Insulin resistance\n• Early-stage hyperglycemia/Diabetes\n• Metabolic Syndrome\n• Thiamine Need\n• Cortisol resistance\n• Fatty liver\n• Liver congestion\n\nA fasting blood sugar level from 100 to 125 mg/dL (5.6 to 6.9 mmol/L) is considered prediabetes. If it's 126 mg/dL (7 mmol/L) or higher on two separate tests, you have diabetes.\nPlease consult with your MD to diagnose an underlined condition and find a way to normalize your glucose levels. Keep using Lab Me to control your blood glucose over time. ",
        high_risk_text:
          "You are at risk! Your blood glucose is significantly elevated. In most cases, it may indicate you have diabetes. If your blood glucose 126 mg/dL (7 mmol/L) or higher on two separate tests, you have diabetes. Please consult with your MD immediately to find a way to manage this. Keep using Lab Me to control your blood glucose over time. ",
      },
      female: {
        test_min: 60,
        border_min: 70,
        normal_min: 72,
        normal_max: 100,
        border_max: 180,
        test_max: 400,
        range_text: "Normal range 72 - 100 mg/dl",
        low_risk_text:
          "You are at risk! Your blood glucose levels are lower than normal, which may be life-threatening in case of extremely low values. \n\nPossible causes include\n\n• Reactive hypoglycemia due to diabetes treatment or other conditions\n• Problems mobilizing glycogen from the liver\n• Hyperinsulinism\n• Adrenal hypofunction\n\nPlease contact your MD immediately to manage this condition, which may be life-threatening in case of extremely low values. \nKeep using Lab Me to control your blood glucose and keep it within the normal range. ",
        low_borderline_text:
          "Attention! Your blood glucose is borderline low. This may be caused by a variety of reasons, such as:\n\n• Problems mobilizing glycogen from the liver\n• Overproduction of insulin\n• Adrenal hypofunction\n• Excessive alcohol drinking\n• Chronic kidney disease\n\nAlso, mildly low blood sugar levels are somewhat common for people with diabetes.\n\nPlease consult with your MD to find a way to manage this. Keep using Lab Me to control your blood glucose over time. ",
        normal_text:
          "Your blood glucose is within the normal range! Keep using Lab Me to monitor your progress over time. ",
        high_borderline_text:
          "Attention! Your blood glucose is borderline high. This may be caused by the following:\n\n• Insulin resistance\n• Early-stage hyperglycemia/Diabetes\n• Metabolic Syndrome\n• Thiamine Need\n• Cortisol resistance\n• Fatty liver\n• Liver congestion\n\nA fasting blood sugar level from 100 to 125 mg/dL (5.6 to 6.9 mmol/L) is considered prediabetes. If it's 126 mg/dL (7 mmol/L) or higher on two separate tests, you have diabetes.\nPlease consult with your MD to diagnose an underlined condition and find a way to normalize your glucose levels. Keep using Lab Me to control your blood glucose over time. ",
        high_risk_text:
          "You are at risk! Your blood glucose is significantly elevated. In most cases, it may indicate you have diabetes. If your blood glucose 126 mg/dL (7 mmol/L) or higher on two separate tests, you have diabetes. Please consult with your MD immediately to find a way to manage this. Keep using Lab Me to control your blood glucose over time. ",
      },
    },
  },
  hba1c: {
    category: "Metabolic Function",
    name: "HbA1c",
    long_name: "Metabolic Function",
    uom: "%",
    description:
      "The higher your blood sugar levels, the more hemoglobin you'll have with sugar attached. An A1C level of 6.5 percent or higher on two separate tests indicates that you may have diabetes. An A1C between 5.7 and 6.4 percent indicates prediabetes. Below 5.7 is considered normal.   Hemoglobin A1c, also called A1c or glycated hemoglobin, is hemoglobin with glucose attached. The A1c test evaluates the average amount of glucose in the blood over the last 2 to 3 months by measuring the percentage of glycated hemoglobin in the blood.\n\nA1c is also used to monitor treatment for individuals diagnosed with diabetes. It helps to evaluate how well your glucose levels have been controlled by treatment over time. For monitoring purposes, an A1c of less than 7% indicates good glucose control and a lower risk of diabetic complications for the majority of people with diabetes.  ",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 4,
        border_min: 4.8,
        normal_min: 5,
        normal_max: 5.7,
        border_max: 6.5,
        test_max: 14,
        range_text: "Normal range 5 - 5.7 %",
        low_risk_text:
          "You are at risk! Your HbA1c levels seem to be lower than normal. If you have been diagnosed with diabetes, the levels of HbA1c lower than 5% may indicate excessive use of antidiabetic drugs or strict diet. We suggest talking to your doctro to find out more. Keep using Lab Me to monitor your progress over time every 2-3 months. ",
        low_borderline_text:
          "Attention! Your HbA1c levels are at borderline low. If you have been diagnosed with diabetes, the levels of HbA1c lower than 5% may indicate excessive use of antidiabetic drugs or strict diet. We suggest talking to your doctro to find out more. Keep using Lab Me to monitor your progress over time every 2-3 months. ",
        normal_text:
          "Looks good!  If you have not previously been diagnosed as having diabetes, and your HbA1c is between 5% and 5.7%, it is likely that you don't have diabetes.  \nIf you have not previously been diagnosed as having diabetes, and your HbA1c is between 5.7% and 6.5%, it indicates that you have an increased risk of developing diabetes in the future (pre-diabetes). If you have been diagnosed with diabetes, your HbA1c is within the target range. ",
        high_borderline_text:
          "Attention! If you have not previously been diagnosed as having diabetes, and your HbA1c is between 5.7% and 6.5%, it indicates that you may have an increased risk of diabetes in the future (pre-diabetes). If you have been diagnosed with diabetes and your HbA1c is below 7%, then you have proper control over your blood glucose levels as HbA1c is within the target range. If it is above 7%, please contact your MD as he/she might need to update your treatment plan. Keep using Lab Me to monitor your HbA1c every 2-3 months.",
        high_risk_text:
          "You are at risk! If you have not been diagnosed with diabetes and your HbA1c level is 6.5% or higher, it indicates that you may have diabetes. \nIf you have been diagnosed with diabetes, it is recommended to keep the HbA1c below 7%. For monitoring glucose control, A1c is currently reported as a percentage and, for most people with diabetes,  The closer they can keep their A1c to the American Diabetes Association (ADA)'s therapeutic goal of less than 7% without experiencing excessive low blood glucose (hypoglycemia), the better their diabetes is in control. As the A1c increases, so does the risk of complications. Please consult with your MD about possible reasons and solutions. Keep using Lab Me to monitor your HbA1c over time.",
      },
      female: {
        test_min: 4,
        border_min: 4.8,
        normal_min: 5,
        normal_max: 5.7,
        border_max: 6.5,
        test_max: 14,
        range_text: "Normal range 5 - 5.7 %",
        low_risk_text:
          "You are at risk! Your HbA1c levels seem to be lower than normal. If you have been diagnosed with diabetes, the levels of HbA1c lower than 5% may indicate excessive use of antidiabetic drugs or strict diet. We suggest talking to your doctro to find out more. Keep using Lab Me to monitor your progress over time every 2-3 months. ",
        low_borderline_text:
          "Attention! Your HbA1c levels are at borderline low. If you have been diagnosed with diabetes, the levels of HbA1c lower than 5% may indicate excessive use of antidiabetic drugs or strict diet. We suggest talking to your doctro to find out more. Keep using Lab Me to monitor your progress over time every 2-3 months. ",
        normal_text:
          "Looks good!  If you have not previously been diagnosed as having diabetes, and your HbA1c is between 5% and 5.7%, it is likely that you don't have diabetes.  \nIf you have not previously been diagnosed as having diabetes, and your HbA1c is between 5.7% and 6.5%, it indicates that you have an increased risk of developing diabetes in the future (pre-diabetes). If you have been diagnosed with diabetes, your HbA1c is within the target range. ",
        high_borderline_text:
          "Attention! If you have not previously been diagnosed as having diabetes, and your HbA1c is between 5.7% and 6.5%, it indicates that you may have an increased risk of diabetes in the future (pre-diabetes). If you have been diagnosed with diabetes and your HbA1c is below 7%, then you have proper control over your blood glucose levels as HbA1c is within the target range. If it is above 7%, please contact your MD as he/she might need to update your treatment plan. Keep using Lab Me to monitor your HbA1c every 2-3 months.",
        high_risk_text:
          "You are at risk! If you have not been diagnosed with diabetes and your HbA1c level is 6.5% or higher, it indicates that you may have diabetes. \nIf you have been diagnosed with diabetes, it is recommended to keep the HbA1c below 7%. For monitoring glucose control, A1c is currently reported as a percentage and, for most people with diabetes,  The closer they can keep their A1c to the American Diabetes Association (ADA)'s therapeutic goal of less than 7% without experiencing excessive low blood glucose (hypoglycemia), the better their diabetes is in control. As the A1c increases, so does the risk of complications. Please consult with your MD about possible reasons and solutions. Keep using Lab Me to monitor your HbA1c over time.",
      },
    },
  },
  hematocrit: {
    category: "Metabolic Function",
    name: "HCT",
    long_name: "Metabolic Function",
    uom: "%",
    description:
      "Hematocrit is the proportion, by volume, of the blood that consists of red blood cells. The hematocrit (hct) is expressed as a percentage. For example, a hematocrit of 25% means that there are 25 milliliters of red blood cells in 100 milliliters of blood. Your hematocrit levels help your doctor evaluate how well your blood supply oxygen to body tissues. ",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 36.4,
        normal_min: 38.3,
        normal_max: 48.6,
        border_max: 51,
        test_max: 100,
        range_text: "Normal range 38.3 - 48.6 %",
        low_risk_text:
          "You are at risk! Your hematocrit is low. This may be due to one of the following reasons:\n\n• Bleeding (ulcers, trauma, colon cancer, internal bleeding)\n• Destruction of red blood cells (sickle cell anemia, enlarged spleen)\n• Decreased production of red blood cells (bone marrow supression, cancer, drugs)\n• Nutritional problems (low iron, B 12, folate and malnutrition)\n• Pregnancy\n• Overhydration (polydypsia, intravenous overhydration)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your hematocrit is borderline low. We strongly recommend you talk to your health care provider to see what you can do to keep it within normal ranges.  \n\nKeep using Lab Me to monitor your progress. \n\n",
        normal_text: "Your hematocrit is within the normal range!\n \n",
        high_borderline_text:
          "Attention! Your hematocrit is borderline high.  We strongly recommend you talk to your health care provider to see what you can do to keep it within normal ranges.  \n\nKeep using Lab Me to monitor your progress over time. \n",
        high_risk_text:
          "You are at risk! Your hematocrit is low. This may be due to one of the following reasons:\n\n• Dehydration (heat exhaustion, no available source of fluids)\n• Low availability of oxygen (smoking, high altitude, pulmonary fibrosis)\n• Genetic (congenital heart diseases)\n• Erythrocytosis (over-production of red blood cells by the bone marrow or polycythemia vera)\n• Cor pulmonale (COPD, chronic sleep apnea, pulmonary embolisms)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 0,
        border_min: 33.7,
        normal_min: 35.5,
        normal_max: 44.9,
        border_max: 47.1,
        test_max: 100,
        range_text: "Normal range 35.5 - 44.9 %",
        low_risk_text:
          "You are at risk! Your hematocrit is low. This may be due to one of the following reasons:\n\n• Bleeding (ulcers, trauma, colon cancer, internal bleeding)\n• Destruction of red blood cells (sickle cell anemia, enlarged spleen)\n• Decreased production of red blood cells (bone marrow supression, cancer, drugs)\n• Nutritional problems (low iron, B 12, folate and malnutrition)\n• Pregnancy\n• Overhydration (polydypsia, intravenous overhydration)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your hematocrit is borderline low. We strongly recommend you talk to your health care provider to see what you can do to keep it within normal ranges.  \n\nKeep using Lab Me to monitor your progress. ",
        normal_text: "Your hematocrit is within the normal range!\n \n",
        high_borderline_text:
          "Attention! Your hematocrit is borderline high.  We strongly recommend you talk to your health care provider to see what you can do to keep it within normal ranges.  \n\nKeep using Lab Me to monitor your progress over time. \n",
        high_risk_text:
          "You are at risk! Your hematocrit is low. This may be due to one of the following reasons:\n\n• Dehydration (heat exhaustion, no available source of fluids)\n• Low availability of oxygen (smoking, high altitude, pulmonary fibrosis)\n• Genetic (congenital heart diseases)\n• Erythrocytosis (over-production of red blood cells by the bone marrow or polycythemia vera)\n• Cor pulmonale (COPD, chronic sleep apnea, pulmonary embolisms)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  hemoglobin: {
    category: "Metabolic Function",
    name: "HGB",
    long_name: "Metabolic Function",
    uom: "grams/L",
    description:
      'Hemoglobin is a complex protein found in red blood cells that contains an iron molecule. The main function of hemoglobin is to carry oxygen from the lungs to the body tissues, and to exchange the oxygen for carbon dioxide, and then carry the carbon dioxide back to the lungs and where it is exchanged for oxygen. The iron molecule in hemoglobin helps maintain the normal shape of red blood cells.\nNormal hemoglobin values are related to the person\'s age and sex. Normal values may vary slightly between test systems, and which groups of doctors have determined "normal values" for their group of patients; however, the value ranges are close (vary by about 0.5 g/dl) for almost every group.\n',
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 12.5,
        normal_min: 13.2,
        normal_max: 16.6,
        border_max: 17.4,
        test_max: 50,
        range_text: "Normal range 13.2 - 16.6 grams/L",
        low_risk_text:
          "You are at risk! Your hemoglobin levels are below the normal ranges. This may be due to one of the following reasons:\n\n• Aplastic anemia\n• Cancer\n• Certain medications, such as antiretroviral drugs for HIV infection and chemotherapy drugs for cancer and other conditions\n• Chronic kidney disease\n• Cirrhosis\n• Hodgkin's lymphoma (Hodgkin's disease)\n• Hypothyroidism (underactive thyroid)\n• Inflammatory bowel disease (IBD)\n• Iron deficiency anemia\n• Lead poisoning\n• Leukemia\n• Multiple myeloma\n• Myelodysplastic syndromes\n• Non-Hodgkin's lymphoma\n• Rheumatoid arthritis\n• Vitamin B12/folate deficiency anemia\n• Enlarged spleen (splenomegaly)\n• Hemolysis\n• Porphyria\n• Sickle cell anemia\n• Thalassemia\n• Frequent blood donation\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your hemoglobin is borderline low. Please consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me Analytics to monitor your progress over time.\n",
        normal_text: "Your hemoglobin is within the normal range!\n",
        high_borderline_text:
          "Attention! Your hemoglobin is borderline high. Please consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your hemoglobin levels are higher than normal. This may be due to one of the following:\n\n• Tobacco smoking\n• Living at a high altitude\n• Overdose or inappropriate use of the drug epoetin alfa (Epogen, Procrit)\n• Blood doping (increasing RBC's by using certain drugs)\n• Lung disease (emphysema, COPD)\n• Bone marrow disorders (polycythemia vera)\n• Cancer\n• Dehydration\n• Heart failure\n• Congenital heart disease in adults\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 0,
        border_min: 11,
        normal_min: 11.6,
        normal_max: 15,
        border_max: 15.8,
        test_max: 50,
        range_text: "Normal range 11.6 - 15 grams/L",
        low_risk_text:
          "You are at risk! Your hemoglobin levels are below the normal ranges. This may be due to one of the following reasons:\n\n• Aplastic anemia\n• Cancer\n• Certain medications, such as antiretroviral drugs for HIV infection and chemotherapy drugs for cancer and other conditions\n• Chronic kidney disease\n• Cirrhosis\n• Hodgkin's lymphoma (Hodgkin's disease)\n• Hypothyroidism (underactive thyroid)\n• Inflammatory bowel disease (IBD)\n• Iron deficiency anemia\n• Lead poisoning\n• Leukemia\n• Multiple myeloma\n• Myelodysplastic syndromes\n• Non-Hodgkin's lymphoma\n• Rheumatoid arthritis\n• Vitamin B12/folate deficiency anemia\n• Enlarged spleen (splenomegaly)\n• Hemolysis\n• Porphyria\n• Sickle cell anemia\n• Thalassemia\n• Frequent blood donation\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your hemoglobin is borderline low. Please consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me Analytics to monitor your progress over time.\n",
        normal_text: "Your hemoglobin is within the normal range!\n",
        high_borderline_text:
          "Attention! Your hemoglobin is borderline high. Please consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your hemoglobin levels are higher than normal. This may be due to one of the following:\n\n• Tobacco smoking\n• Living at a high altitude\n• Overdose or inappropriate use of the drug epoetin alfa (Epogen, Procrit)\n• Blood doping (increasing RBC's by using certain drugs)\n• Lung disease (emphysema, COPD)\n• Bone marrow disorders (polycythemia vera)\n• Cancer\n• Dehydration\n• Heart failure\n• Congenital heart disease in adults\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  mean_platelet_volume: {
    category: "Metabolic Function",
    name: "Mean Platelet Volume",
    long_name: "Metabolic Function",
    uom: "fL",
    description:
      "An MPV test measures the average size of your platelets. Having a high or low MPV doesn't mean anything on its own. It should be interpreted within the context of other CBC results, such as platelet count. In most cases, your doctor will simply use your MPV test results to decide whether or not to do additional testing, such as a bone marrow biopsy.\n",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 6.8,
        normal_min: 7.2,
        normal_max: 11.7,
        border_max: 12.3,
        test_max: 25,
        range_text: "Normal range 7.2 - 11.7 fL",
        low_risk_text:
          "You are at risk! Your MPV level is below the normal ranges. This may be associated with the following:\n\n\n• Bone marrow failure\n• Lupus\n• Splenomegaly (an enlargement of the spleen), which often causes platelets to be trapped in the spleen\n• Medications that suppress platelet formation, such as chemotherapy\n• Hypothyroidism\n• Iron deficiency anemia\n• HIV/AIDS\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! You MPV is borderline low. \n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MPV is within the normal range. However, we also recommend you check your platelet count, if you have not yet. ",
        high_borderline_text:
          "Attention! You MPV is borderline high.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MPV is above the normal ranges. This may be associated with one of the following:\n\n• Blood cancers such as leukemia\n• Non-alcoholic liver disease\n• Genetic abnormalities in platelets\n• Bernard-Soulier Disease (giant platelet syndrome)\n• Bone marrow stimulating drugs, such as erythropoietin or thrombopoietin\n• Diabetes mellitus\n• Respiratory Diseases\n• Sepsis\n• Hyperthyroidism\n• Heart attack (see below)\n• Pre-eclampsia (toxemia of pregnancy) and HELLP syndrome\n• Artificial heart valves\n• Idiopathic thrombocytopenic purpura (ITP)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 0,
        border_min: 6.8,
        normal_min: 7.2,
        normal_max: 11.7,
        border_max: 12.3,
        test_max: 25,
        range_text: "Normal range 7.2 - 11.7 fL",
        low_risk_text:
          "You are at risk! Your MPV level is below the normal ranges. This may be associated with the following:\n\n\n• Bone marrow failure\n• Lupus\n• Splenomegaly (an enlargement of the spleen), which often causes platelets to be trapped in the spleen\n• Medications that suppress platelet formation, such as chemotherapy\n• Hypothyroidism\n• Iron deficiency anemia\n• HIV/AIDS\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! You MPV is borderline low. \n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MPV is within the normal range. However, we also recommend you check your platelet count, if you have not yet. ",
        high_borderline_text:
          "Attention! You MPV is borderline high.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MPV is above the normal ranges. This may be associated with one of the following:\n\n• Blood cancers such as leukemia\n• Non-alcoholic liver disease\n• Genetic abnormalities in platelets\n• Bernard-Soulier Disease (giant platelet syndrome)\n• Bone marrow stimulating drugs, such as erythropoietin or thrombopoietin\n• Diabetes mellitus\n• Respiratory Diseases\n• Sepsis\n• Hyperthyroidism\n• Heart attack (see below)\n• Pre-eclampsia (toxemia of pregnancy) and HELLP syndrome\n• Artificial heart valves\n• Idiopathic thrombocytopenic purpura (ITP)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  wbc: {
    category: "Stress & Immune Function",
    name: "WBC",
    long_name: "Stress & Immune Function",
    uom: "billion cells/L",
    description:
      "White blood cells (WBCs), also called leukocytes, are cells that exist in the blood, the lymphatic system, and tissues and are an important part of the body's defense system. They help protect against infections and also have a role in inflammation, allergic responses, and protecting against cancer. ",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 3.2,
        normal_min: 3.4,
        normal_max: 9.6,
        border_max: 10.1,
        test_max: 50,
        range_text: "Normal range 3.4 - 9.6 billion cells/L",
        low_risk_text:
          "You are at risk! Your WBC’s count is lower than normal.\n\nA low white blood cell count, called leukopenia, can result from conditions such as:\n\n• Dietary deficiencies\n• Bone marrow disorders—the bone marrow does not produce sufficient WBCs (e.g., myelodysplastic syndrome, vitamin B12 or folate deficiency)\n• Bone marrow damage (e.g., toxin, specific chemotherapy, radiation therapy)\n• Autoimmune disorders—the body attacks and destroys its own WBCs (e.g., lupus)\n• Serious infections (e.g., sepsis)\n• Diseases of the immune system, such as HIV, which destroy T lymphocytes\n• Lymphoma or other cancer that has spread (metastasized) to the bone marrow\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your WBC's count is borderline low. \n\nPlease consult with your MD to find out how to normalize these levels.  Keep using Lab Me  to monitor your progress over time.\n",
        normal_text:
          "Your WBC's levels are within the normal limits!  Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your WBC's count is borderline low. \n\nPlease consult with your MD to find out how to normalize these levels.  Keep using Lab Me  to monitor your progress over time\n",
        high_risk_text:
          "You are at risk! Your WBC levels are higher than normal ranges.\n\nA high white blood cell count, called leukocytosis, may result from a number of conditions and diseases. Some examples include:\n\n• Digestive leukocytosis (did you take this test after a meal?)\n• Infections, most commonly caused by bacteria and some viruses, less commonly by fungi or parasites\n• Inflammation or inflammatory conditions such as rheumatoid arthritis, vasculitis or inflammatory bowel disease\n• Allergic responses (e.g., allergies, asthma)\n• Conditions that result in tissue death (necrosis) such as trauma, burns, surgery or heart attack\n• Bone marrow disorders such as cancer (leukemia, myeloproliferative neoplasms)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 3.2,
        normal_min: 3.4,
        normal_max: 9.6,
        border_max: 10.1,
        test_max: 50,
        range_text: "Normal range 3.4 - 9.6 billion cells/L",
        low_risk_text:
          "You are at risk! Your WBC’s count is lower than normal.\n\nA low white blood cell count, called leukopenia, can result from conditions such as:\n\n• Dietary deficiencies\n• Bone marrow disorders—the bone marrow does not produce sufficient WBCs (e.g., myelodysplastic syndrome, vitamin B12 or folate deficiency)\n• Bone marrow damage (e.g., toxin, specific chemotherapy, radiation therapy)\n• Autoimmune disorders—the body attacks and destroys its own WBCs (e.g., lupus)\n• Serious infections (e.g., sepsis)\n• Diseases of the immune system, such as HIV, which destroy T lymphocytes\n• Lymphoma or other cancer that has spread (metastasized) to the bone marrow\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your WBC's count is borderline low. \n\nPlease consult with your MD to find out how to normalize these levels.  Keep using Lab Me  to monitor your progress over time.\n",
        normal_text:
          "Your WBC's levels are within the normal limits!  Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your WBC's count is borderline low. \n\nPlease consult with your MD to find out how to normalize these levels.  Keep using Lab Me  to monitor your progress over time\n",
        high_risk_text:
          "You are at risk! Your WBC levels are higher than normal ranges.\n\nA high white blood cell count, called leukocytosis, may result from a number of conditions and diseases. Some examples include:\n\n• Digestive leukocytosis (did you take this test after a meal?)\n• Infections, most commonly caused by bacteria and some viruses, less commonly by fungi or parasites\n• Inflammation or inflammatory conditions such as rheumatoid arthritis, vasculitis or inflammatory bowel disease\n• Allergic responses (e.g., allergies, asthma)\n• Conditions that result in tissue death (necrosis) such as trauma, burns, surgery or heart attack\n• Bone marrow disorders such as cancer (leukemia, myeloproliferative neoplasms)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  monocyte_percentage: {
    category: "Stress & Immune Function",
    name: "Monocyte Percentage",
    long_name: "Stress & Immune Function",
    uom: "%",
    description:
      "Monocytes are a type of white blood cell. They help fight bacteria, viruses, and other infections in your body. Your bone marrow produces monocytes and releases them into your bloodstream. Once they reach tissues in your body, they’re called macrophages. There, they isolate and gobble up germs and other harmful microorganisms. They also get rid of dead cells and assist in the immune response.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 1.9,
        normal_min: 2,
        normal_max: 10,
        border_max: 10.5,
        test_max: 100,
        range_text: "Normal range 2 - 10 %",
        low_risk_text:
          "You are at risk! Your monocyte percentage is lower than the normal ranges.\n\nA decrease in monocytes may be caused by:\n\n• bloodstream infection\n• skin infections\n• chemotherapy\n• bone marrow disorder\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your monocyte percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your monocyte percentage is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your monocyte percentage is borderline high. Please consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        high_risk_text:
          "You are at risk! Your monocyte percentage is higher than the normal ranges.\n\nAn increase in monocytes may be caused by:\n\n• Chronic inflammatory disease\n• Viral infection, such as measles, mononucleosis, and mumps\n• Tuberculosis\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 1.9,
        normal_min: 2,
        normal_max: 10,
        border_max: 10.5,
        test_max: 100,
        range_text: "Normal range 2 - 10 %",
        low_risk_text:
          "You are at risk! Your monocyte percentage is lower than the normal ranges.\n\nA decrease in monocytes may be caused by:\n\n• bloodstream infection\n• skin infections\n• chemotherapy\n• bone marrow disorder\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your monocyte percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your monocyte percentage is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your monocyte percentage is borderline high. Please consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        high_risk_text:
          "You are at risk! Your monocyte percentage is higher than the normal ranges.\n\nAn increase in monocytes may be caused by:\n\n• Chronic inflammatory disease\n• Viral infection, such as measles, mononucleosis, and mumps\n• Tuberculosis\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  lymphocyte_percentage: {
    category: "Stress & Immune Function",
    name: "Lymphocyte Percentage",
    long_name: "Stress & Immune Function",
    uom: "%",
    description:
      "Lymphocytes are cells that circulate in your blood that are part of the immune system. There are two main types lymphocytes: T cells and B cells. B cells produce antibody molecules that can latch on and destroy invading viruses or bacteria. T cells are direct fighters of foreign invaders and also produced cytokines, which are biological substances that help activate other parts of the immune system.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 5,
        border_min: 19,
        normal_min: 20,
        normal_max: 40,
        border_max: 42,
        test_max: 100,
        range_text: "Normal range 20 - 40 %",
        low_risk_text:
          "You are at risk! Your lymphocyte percentage is lower than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chemotherapy\n• HIV/AIDS infection\n• Leukemia\n• Radiation therapy or exposure\n• Sepsis (severe, inflammatory response to bacteria or other germs)\n• Steroid use\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your lymphocyte percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your lymphocyte percentage is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your lymphocyte percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your lymphocyte percentage is higher than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chronic bacterial infection\n• Infectious hepatitis\n• Infectious mononucleosis, or mono\n• Lymphocytic leukemia\n• Multiple myeloma\n• Viral infection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 5,
        border_min: 19,
        normal_min: 20,
        normal_max: 40,
        border_max: 42,
        test_max: 100,
        range_text: "Normal range 20 - 40 %",
        low_risk_text:
          "You are at risk! Your lymphocyte percentage is lower than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chemotherapy\n• HIV/AIDS infection\n• Leukemia\n• Radiation therapy or exposure\n• Sepsis (severe, inflammatory response to bacteria or other germs)\n• Steroid use\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your lymphocyte percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your lymphocyte percentage is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your lymphocyte percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your lymphocyte percentage is higher than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chronic bacterial infection\n• Infectious hepatitis\n• Infectious mononucleosis, or mono\n• Lymphocytic leukemia\n• Multiple myeloma\n• Viral infection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  basophil_percentage: {
    category: "Stress & Immune Function",
    name: "Basophil Percentage",
    long_name: "Stress & Immune Function",
    uom: "%",
    description:
      "Basophils are the least common of the granulocytes, representing about 0.01% to 0.3% of circulating leukocytes (white blood cells). If basophil levels are low, this may be a sign of an allergic reaction or another condition. High basophil levels may indicate an autoimmune condition or one of several types of blood disorder.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 1,
        normal_min: 1,
        normal_max: 2,
        border_max: 2.1,
        test_max: 100,
        range_text: "Normal range 1 - 2 %",
        low_risk_text:
          "You are at risk! Your basophils percentage is lower than the normal ranges. \n\nA decrease in basophils may be caused by:\n\n• Acute allergic reaction.\n• Infections\n• Hyperthyroidism (when a thyroid gland produces too much thyroid hormone)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your basophil percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your basophil percentage is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your basophil percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your basophil percentage is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• Rheumatoid arthritis\n• Lupus\n• Inflammatory bowel diseases, such as Crohn’s disease or ulcerative colitis\n• Intestinal parasites\n• Diabetes\n• Allergies and asthma\n• Hypothyroidism\n• Myeloproliferative disorders (disorders that affect the bone marrow)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time\n",
      },
      female: {
        test_min: 0,
        border_min: 1,
        normal_min: 1,
        normal_max: 2,
        border_max: 2.1,
        test_max: 100,
        range_text: "Normal range 1 - 2 %",
        low_risk_text:
          "You are at risk! Your basophils percentage is lower than the normal ranges. \n\nA decrease in basophils may be caused by:\n\n• Acute allergic reaction.\n• Infections\n• Hyperthyroidism (when a thyroid gland produces too much thyroid hormone)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your basophil percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your basophil percentage is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your basophil percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your basophil percentage is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• Rheumatoid arthritis\n• Lupus\n• Inflammatory bowel diseases, such as Crohn’s disease or ulcerative colitis\n• Intestinal parasites\n• Diabetes\n• Allergies and asthma\n• Hypothyroidism\n• Myeloproliferative disorders (disorders that affect the bone marrow)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time\n",
      },
    },
  },
  eosinophil_percentage: {
    category: "Stress & Immune Function",
    name: "Eosinophil Percentage",
    long_name: "Stress & Immune Function",
    uom: "%",
    description:
      "The eosinophil is a specialized cell of the immune system. This proinflammatory white blood cell generally has a nucleus with two lobes (bilobed) and cytoplasm filled with approximately 200 large granules containing enzymes and proteins with different (known and unknown) functions. The functions of the eosinophil are varied, some of which are very similar to other white blood cells. They are implicated in numerous inflammatory processes, especially allergic disorders.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 1,
        normal_min: 1,
        normal_max: 6,
        border_max: 6.3,
        test_max: 100,
        range_text: "Normal range 1 - 6 %",
        low_risk_text:
          "You are at risk! Your eosinophil percentage is lower than the normal ranges. \n\nA decrease in basophils may be a sign of:\n\n• An intoxication from alcohol\n• Excessive production of cortisol, like in Cushing’s disease\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your eosinophil percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your eosinophil percentage is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your eosinophil percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your eosinophil percentage is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• An infection by parasitic worms\n• An autoimmune disease\n• Severe allergic reactions\n• Eczema\n• Asthma\n• Seasonal allergies\n• Leukemia and certain other cancers\n• Ulcerative colitis\n• Scarlet fever\n• Lupus\n• Crohn’s disease\n• A significant drug reaction\n• An organ transplant rejection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 1,
        normal_min: 1,
        normal_max: 6,
        border_max: 6.3,
        test_max: 100,
        range_text: "Normal range 1 - 6 %",
        low_risk_text:
          "You are at risk! Your eosinophil percentage is lower than the normal ranges. \n\nA decrease in basophils may be a sign of:\n\n• An intoxication from alcohol\n• Excessive production of cortisol, like in Cushing’s disease\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your eosinophil percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your eosinophil percentage is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your eosinophil percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your eosinophil percentage is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• An infection by parasitic worms\n• An autoimmune disease\n• Severe allergic reactions\n• Eczema\n• Asthma\n• Seasonal allergies\n• Leukemia and certain other cancers\n• Ulcerative colitis\n• Scarlet fever\n• Lupus\n• Crohn’s disease\n• A significant drug reaction\n• An organ transplant rejection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  neutrophil_percentage: {
    category: "Stress & Immune Function",
    name: "Neutrophil Percentage",
    long_name: "Stress & Immune Function",
    uom: "%",
    description:
      "Neutrophils are a type of white blood cell that helps heal damaged tissues and resolve infections. Neutrophils help prevent infections by blocking, disabling, digesting, or warding off invading particles and microorganisms. They also communicate with other cells to help them repair cells and mount a proper immune response.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 38,
        normal_min: 40,
        normal_max: 80,
        border_max: 84,
        test_max: 100,
        range_text: "Normal range 40 - 80 %",
        low_risk_text:
          "You are at risk! Your neutrophil percentage is lower than the normal ranges. \n\nA decrease in neutrophil may be caused by:\n\n• Severe or chronic bacterial infections\n• Allergic disorders\n• Certain drug treatments (phenytoin and sulfa drugs)\n• Autoimmune disorders\n• Cancer\n• Viral infections, such as influenza\n• Bacteria infections, such as tuberculosis\n• Myelofibrosis, a disorder that involves bone marrow scarring\n• Vitamin B-12 deficiency\n• Radiation therapy involving bone marrow\n• Chemotherapy medications\n• Toxins, such as benzenes and insecticides\n• Aplastic anemia, when the bone marrow stops producing enough blood cells\n• Severe congenital neutropenia, a group of disorders where neutrophils cannot mature\n• Cyclic neutropenia, which causes cell levels to rise and fall\n• Chronic benign neutropenia, which causes low cell levels for no apparent reason\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your neutrophil percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your neutrophil percentage is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your neutrophil percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your neutrophil percentage is higher than the normal ranges. \n\nAn increase in neutrophil may be caused by:\n\n• Infections\n• Injuries\n• Some medications, such as corticosteroids, beta-2-agonists, and epinephrine\n• Some cancers\n• Physical or emotional stress\n• Surgery or accidents\n• Smoking tobacco\n• Pregnancy\n• Obesity\n• Genetic conditions, such as Down syndrome\n• Surgical removal of the spleen\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 38,
        normal_min: 40,
        normal_max: 80,
        border_max: 84,
        test_max: 100,
        range_text: "Normal range 40 - 80 %",
        low_risk_text:
          "You are at risk! Your neutrophil percentage is lower than the normal ranges. \n\nA decrease in neutrophil may be caused by:\n\n• Severe or chronic bacterial infections\n• Allergic disorders\n• Certain drug treatments (phenytoin and sulfa drugs)\n• Autoimmune disorders\n• Cancer\n• Viral infections, such as influenza\n• Bacteria infections, such as tuberculosis\n• Myelofibrosis, a disorder that involves bone marrow scarring\n• Vitamin B-12 deficiency\n• Radiation therapy involving bone marrow\n• Chemotherapy medications\n• Toxins, such as benzenes and insecticides\n• Aplastic anemia, when the bone marrow stops producing enough blood cells\n• Severe congenital neutropenia, a group of disorders where neutrophils cannot mature\n• Cyclic neutropenia, which causes cell levels to rise and fall\n• Chronic benign neutropenia, which causes low cell levels for no apparent reason\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your neutrophil percentage is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your neutrophil percentage is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your neutrophil percentage is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your neutrophil percentage is higher than the normal ranges. \n\nAn increase in neutrophil may be caused by:\n\n• Infections\n• Injuries\n• Some medications, such as corticosteroids, beta-2-agonists, and epinephrine\n• Some cancers\n• Physical or emotional stress\n• Surgery or accidents\n• Smoking tobacco\n• Pregnancy\n• Obesity\n• Genetic conditions, such as Down syndrome\n• Surgical removal of the spleen\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  total_testosterone: {
    category: "Stress & Immune Function",
    name: "Total Testosterone",
    long_name: "Stress & Immune Function",
    uom: "ng/dl",
    description:
      "Testosterone is a sex hormone that plays important roles in the body. In men, it’s thought to regulate sex drive (libido), bone mass, fat distribution, muscle mass and strength, and the production of red blood cells and sperm. Women also have testosterone in their bodies, but in much smaller amounts.\n\nThis test measures the levels of testosterone in your blood. Most of the testosterone in the blood is attached to proteins. Testosterone that is not attached to a protein is called free testosterone. A testosterone levels test may be used to diagnose several conditions, including:\n\n• Decreased sex drive in men and women\n• Infertility in men and women\n• Erectile dysfunction in men\n• Tumors of testicles in men\n• Early or delayed puberty in boys\n• Excess body hair growth and development of masculine features in women\n• Irregular menstrual periods in women",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 130,
        border_min: 266,
        normal_min: 280,
        normal_max: 1100,
        border_max: 1155,
        test_max: 1500,
        range_text: "Normal range 280 - 1100 ng/dl",
        low_risk_text:
          "You are at risk! Your total testosterone levels are lower than the normal ranges. \n\nThis may be caused by one of the following:\n\n• Injury (trauma, interrupted blood supply to the testes) or infection of the testes (orchitis)\n• Chemotherapy for cancer\n• Metabolic disorders such as hemochromatosis (too much iron in the body)\n• Dysfunction or tumors of the pituitary gland\n• Medications, including opioids, hormones used to treat prostate cancer, and steroids (such as prednisone)\n• Acute (short-term) or chronic (long-term) illness\n• Alcohol abuse\n• Cirrhosis of the liver\n• Chronic renal (kidney) failure\n• HIV/AIDS\n• Inflammatory conditions such as sarcoidosis (a condition that causes inflammation of the lungs and other organs)\n• Kallman syndrome (abnormal development of the hypothalamus, a gland in the brain that controls many hormones)\n• Klinefelter syndrome (a genetic condition in which a male is born with an extra copy of the X chromosome). Also called XXY syndrome\n• High levels of the milk-producing hormone prolactin\n• Obesity or extreme weight loss\n• Uncontrolled type 2 diabetes mellitus\n• Congenital defect (present at birth)\n• Obstructive sleep apnea\n• Aging\n• Estrogen excess (usually from an external or environmental source)\n• Previous anabolic steroid abuse\n• Severe primary hypothyroidism\n• Pubertal delay\n• Trauma (head injury)\n• Radiation exposure or prior surgery of the brain\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your total testosterone levels are borderline low.\n\nPlease consult with your MD to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.",
        normal_text: "Your testosterone levels are within optimal range.",
        high_borderline_text:
          "Attention! Your total testosterone levels are borderline high.\n\nPlease consult with your MD to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your total testosterone levels are higher than the normal ranges. \n\nThis may be caused by one of the following:\n\n• Adrenal and testicular tumours\n• Anabolic steroid abuse \n• Testosterone supplementation\n• Testosterone gel\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  tsh: {
    category: "Stress & Immune Function",
    name: "TSH",
    long_name: "Stress & Immune Function",
    uom: "µIU/mL\n",
    description:
      "TSH stands for “thyroid stimulating hormone” and the test measures how much of this hormone is in your blood. TSH is produced by the pituitary gland in your brain. This gland tells your thyroid to make and release the thyroid hormones into your blood. Your thyroid will produce more hormones if your pituitary gland produces more TSH. In this way, the two glands work together to make sure the right amount of thyroid hormones are produced.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0.2,
        border_min: 0.4,
        normal_min: 0.5,
        normal_max: 4.15,
        border_max: 4.5,
        test_max: 100,
        range_text: "Normal range 0.5 - 4.15 µIU/mL\n",
        low_risk_text:
          "You are at risk! Your TSH levels are low. \n\nThis may be caused by one of the following:\n\n• An overactive thyroid gland (hyperthyroidism); Graves disease is the most common cause of hyperthyroidism.\n• Insufficient anti-thyroid medication in a person being treated for hyperthyroidism\n• Damage to the pituitary gland that prevents it from producing adequate amounts of TSH\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your TSH levels are borderline low. The may indicate subclinical hyperthyroidism. \n\nPlease consult with your MD to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
        normal_text: "Your TSH levels are within the normal range!",
        high_borderline_text:
          "Attention! Your TSH levels are borderline high. The may indicate hypothyroidism, a condition in which your thyroid is not making enough thyroid hormones. . \n\nPlease consult with your MD to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
        high_risk_text:
          "You are at risk! Your TSH levels are higher than normal ranges. \n\nThis may be caused by one of the following:\n\n• Hashimoto thyroiditis\n• A person with hypothyroidism or who has had their thyroid gland removed is receiving too little thyroid hormone replacement medication and the dose may need to be adjusted\n• A person with hyperthyroidism is receiving too much anti-thyroid medication and the dose needs adjusting\n• Tumor of the pituitary gland producing unregulated levels of TSH\n• A rare inherited disorder is present in which the body and/or pituitary do not respond normally to thyroid hormones.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0.2,
        border_min: 0.39,
        normal_min: 0.4,
        normal_max: 2.34,
        border_max: 4.5,
        test_max: 100,
        range_text: "Normal range 0.4 - 2.34 µIU/mL\n",
        low_risk_text:
          "You are at risk! Your TSH levels are low. \n\nThis may be caused by one of the following:\n\n• An overactive thyroid gland (hyperthyroidism); Graves disease is the most common cause of hyperthyroidism.\n• Insufficient anti-thyroid medication in a person being treated for hyperthyroidism\n• Damage to the pituitary gland that prevents it from producing adequate amounts of TSH\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your TSH levels are borderline low. The may indicate subclinical hyperthyroidism. \n\nPlease consult with your MD to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
        normal_text: "Your TSH levels are within the normal range!",
        high_borderline_text:
          "Attention! Your TSH levels are borderline high. The may indicate hypothyroidism, a condition in which your thyroid is not making enough thyroid hormones. . \n\nPlease consult with your MD to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
        high_risk_text:
          "You are at risk! Your TSH levels are higher than normal ranges. \n\nThis may be caused by one of the following:\n\n• Hashimoto thyroiditis\n• A person with hypothyroidism or who has had their thyroid gland removed is receiving too little thyroid hormone replacement medication and the dose may need to be adjusted\n• A person with hyperthyroidism is receiving too much anti-thyroid medication and the dose needs adjusting\n• Tumor of the pituitary gland producing unregulated levels of TSH\n• A rare inherited disorder is present in which the body and/or pituitary do not respond normally to thyroid hormones.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. He will probably order additional tests to determine the cause of your thyroid problem. These tests may include:\n\nFree & Total T4\nFree & Total T3\nReverse T3\nThyroid peroxidase antibody (TPO)\nThyroglobulin antibody (TGAb)\nThyroid stimulating hormone receptor antibodies (TSHRAb)\n\nKeep using Lab Me to monitor your progress over time.",
      },
    },
  },
  vitamin_d: {
    category: "Stress & Immune Function",
    name: "Vitamin D",
    long_name: "Stress & Immune Function",
    uom: "ng/mL",
    description:
      "Vitamin D helps your body absorb calcium and helps with maintaing healthy skin and bones. It also plays an important role in your immune system. Vitamin D deficiency is linked to fatigue, bone and muscle pain, as well as getting ill more often than usual.  A vitamin D test is used to screen for or monitor bone disorders. It is also sometimes used to check vitamin D levels in people with chronic illnesses such as asthma, psoriasis, and certain autoimmune diseases.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 15,
        border_min: 28.5,
        normal_min: 30,
        normal_max: 80,
        border_max: 125,
        test_max: 150,
        range_text: "Normal range 30 - 80 ng/mL",
        low_risk_text:
          "You are at risk! Your vitamin D levels are lower than normal ranges. \n\nA low blood level of 25-hydroxyvitamin D may mean that a person is not getting enough exposure to sunlight or enough dietary vitamin D to meet his or her body's demand or that there is a problem with its absorption from the intestines. Occasionally, drugs used to treat seizures, particularly phenytoin (Dilantin), can interfere with the production of 25-hydroxyvitamin D in the liver.\nThere is some evidence that vitamin D deficiency may increase the risk of some cancers, immune diseases, and cardiovascular disease.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.\n\nKeep using Lab Me to monitor your progress over time to make sure you maintain the optimal vitamin D levels.",
        low_borderline_text:
          "Your Vitamin D levels are borderline low. \n\nThis may indicate that you are not getting enough sunlight or dietary vitamin D or that the absorption of vitamin D in the intestine is impaired. Please consult with your MD to find out how to normalize your vitamin D levels.\n\nKeep using Lab Me to monitor your progress over time.",
        normal_text:
          "Your Vitamin D levels are within the normal range. Keep using Lab Me to track your progress over time to make sure it stays within the optimal ranges. ",
        high_borderline_text:
          "Your Vitamin D levels are borderline high. Please consult with your MD to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        high_risk_text:
          "You are at risk! Your vitamin D levels are high. A high level of 25-hydroxyvitamin D usually reflects excess supplementation from vitamin pills or other nutritional supplements. However, high level of 25-hydroxyvitamin D may also be caused by:\n\n• Estrogen therapy\n• Certain medications (thiazide diuretics, digoxin, isoniazide)\n• Kidney disease\n• Liver disease\n• Hyperparathyroidism\n• Sarcoidosis\n• Histoplasmosis\n\nPlease consult with your MD to determine the cause and how to normalize your vitamin D levels.\n\nKeep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 15,
        border_min: 28.5,
        normal_min: 30,
        normal_max: 80,
        border_max: 125,
        test_max: 150,
        range_text: "Normal range 30 - 80 ng/mL",
        low_risk_text:
          "You are at risk! Your vitamin D levels are lower than normal ranges. \n\nA low blood level of 25-hydroxyvitamin D may mean that a person is not getting enough exposure to sunlight or enough dietary vitamin D to meet his or her body's demand or that there is a problem with its absorption from the intestines. Occasionally, drugs used to treat seizures, particularly phenytoin (Dilantin), can interfere with the production of 25-hydroxyvitamin D in the liver.\nThere is some evidence that vitamin D deficiency may increase the risk of some cancers, immune diseases, and cardiovascular disease.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.\n\nKeep using Lab Me to monitor your progress over time to make sure you maintain the optimal vitamin D levels.",
        low_borderline_text:
          "Your Vitamin D levels are borderline low. \n\nThis may indicate that you are not getting enough sunlight or dietary vitamin D or that the absorption of vitamin D in the intestine is impaired. Please consult with your MD to find out how to normalize your vitamin D levels.\n\nKeep using Lab Me to monitor your progress over time.",
        normal_text:
          "Your Vitamin D levels are within the normal range. Keep using Lab Me to track your progress over time to make sure it stays within the optimal ranges. ",
        high_borderline_text:
          "Your Vitamin D levels are borderline high. Please consult with your MD to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        high_risk_text:
          "You are at risk! Your vitamin D levels are high. A high level of 25-hydroxyvitamin D usually reflects excess supplementation from vitamin pills or other nutritional supplements. However, high level of 25-hydroxyvitamin D may also be caused by:\n\n• Estrogen therapy\n• Certain medications (thiazide diuretics, digoxin, isoniazide)\n• Kidney disease\n• Liver disease\n• Hyperparathyroidism\n• Sarcoidosis\n• Histoplasmosis\n\nPlease consult with your MD to determine the cause and how to normalize your vitamin D levels.\n\nKeep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  mch: {
    category: "Stress & Immune Function",
    name: "MCH",
    long_name: "Stress & Immune Function",
    uom: "picograms",
    description:
      "An MCH value refers to the average quantity of hemoglobin present in a single red blood cell. Hemoglobin is the protein in your red blood cells that transports oxygen to the tissues of your body.\n",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 10,
        border_min: 26.1,
        normal_min: 27.5,
        normal_max: 33.2,
        border_max: 34.9,
        test_max: 50,
        range_text: "Normal range 27.5 - 33.2 picograms",
        low_risk_text:
          "You are at risk! Your MCH levels are low. This may be cause by one of the following:\n\n• Anemia- Iron deficiency\n• Anemia- B6 deficiency\n• Internal bleeding\n• Heavy metal body burden\n• Vitamin C need\n\nIf you have a low MCH value, you may experience the following symptoms:\n\n• Shortness of breath\n• Chest pain\n• Fast heartbeat\n• Fatigue or weakness\n• Very pale or yellowish skin\n• Headache\n\nPlease consult with your MD to determine the cause and how to normalize your MCH levels. He might order other tests to differentiate between possible causes of low MCH.\n",
        low_borderline_text:
          "Attention! You are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MCH is within the normal ranges. Keep using Lab Me to monitor your progress over time.\n\n",
        high_borderline_text:
          "Attention! You are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MCH is above the normal ranges.\n\nAn increased level of MCH is usually caused by anemia due to a deficiency of B vitamins, particularly B-12 and folate. \n\nHigh MCH scores may also be the result of the following:\n\n• Liver diseases\n• An overactive thyroid gland\n• Drinking alcohol regularly\n• Complications from certain cancers\n• Complications from an infection\n• Taking too many medications containing estrogen\n\nIf you have a high MCH value, you may experience the following symptoms:\n\n• Tiredness\n• Very pale skin\n• Fast heartbeat\n• Nails that are brittle and easily broken\n• Brain fog or poor concentration\n• Confusion and memory loss\n\nIf you have anemia that’s due to B-12 deficiency, you may also experience:\n\n• Tingling or “pins and needles” in your hands or feet\n• Nausea or vomiting\n• Bloating and gas\n• Mental symptoms, such as depression or confusion.\n\nIf you have anemia due to folate deficiency, you could experience the following additional symptoms:\n\n• Diarrhea\n• Decrease in appetite\n• Irritability\n• A smooth or sensitive tongue\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 10,
        border_min: 26.1,
        normal_min: 27.5,
        normal_max: 33.2,
        border_max: 34.9,
        test_max: 50,
        range_text: "Normal range 27.5 - 33.2 picograms",
        low_risk_text:
          "You are at risk! Your MCH levels are low. This may be cause by one of the following:\n\n• Anemia- Iron deficiency\n• Anemia- B6 deficiency\n• Internal bleeding\n• Heavy metal body burden\n• Vitamin C need\n\nIf you have a low MCH value, you may experience the following symptoms:\n\n• Shortness of breath\n• Chest pain\n• Fast heartbeat\n• Fatigue or weakness\n• Very pale or yellowish skin\n• Headache\n\nPlease consult with your MD to determine the cause and how to normalize your MCH levels. He might order other tests to differentiate between possible causes of low MCH.\n",
        low_borderline_text:
          "Attention! You are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MCH is within the normal ranges. Keep using Lab Me to monitor your progress over time.\n\n",
        high_borderline_text:
          "Attention! You are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MCH is above the normal ranges.\n\nAn increased level of MCH is usually caused by anemia due to a deficiency of B vitamins, particularly B-12 and folate. \n\nHigh MCH scores may also be the result of the following:\n\n• Liver diseases\n• An overactive thyroid gland\n• Drinking alcohol regularly\n• Complications from certain cancers\n• Complications from an infection\n• Taking too many medications containing estrogen\n\nIf you have a high MCH value, you may experience the following symptoms:\n\n• Tiredness\n• Very pale skin\n• Fast heartbeat\n• Nails that are brittle and easily broken\n• Brain fog or poor concentration\n• Confusion and memory loss\n\nIf you have anemia that’s due to B-12 deficiency, you may also experience:\n\n• Tingling or “pins and needles” in your hands or feet\n• Nausea or vomiting\n• Bloating and gas\n• Mental symptoms, such as depression or confusion.\n\nIf you have anemia due to folate deficiency, you could experience the following additional symptoms:\n\n• Diarrhea\n• Decrease in appetite\n• Irritability\n• A smooth or sensitive tongue\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  mchc: {
    category: "Stress & Immune Function",
    name: "MCHC",
    long_name: "Stress & Immune Function",
    uom: "grams/dL",
    description:
      "The mean corpuscular hemoglobin concentration (MCHC) is the average concentration of hemoglobin in your red blood cells. Hemoglobin is the protein molecule that allows red blood cells to carry oxygen to tissues within your body.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 10,
        border_min: 31.7,
        normal_min: 33.4,
        normal_max: 35.5,
        border_max: 37.3,
        test_max: 50,
        range_text: "Normal range 33.4 - 35.5 grams/dL",
        low_risk_text:
          "You are at risk! Your MCHC is below the normal ranges.\n\nThe most common cause of low MCHC is anemia. Hypochromic microcytic anemia commonly results in low MCHC. This condition means your red blood cells are smaller than usual and have a decreased level of hemoglobin.\n\nThis type of microcytic anemia can be caused by:\n\n• Lack of iron\n• Inability of your body to absorb iron, which can be caused by conditions like celiac disease, Crohn’s disease, and gastric bypass surgery\n• Chronic low-grade blood loss over time from a long menstrual cycle or peptic ulcers\n• Hemolysis, or the premature destruction of red blood cells over time\n• Copper deficiency\n• Zinc excess, which causes copper deficiency\n• Alcohol use\n• Drug use\n\nIn more rare cases, low MCHC and hypochromic microcytic anemia can be caused by:\n\n• Cancer, including cancers that cause internal blood loss\n• Parasitic infections like hookworm infections\n• Lead poisoning\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MCHC is within the normal ranges. Keep using Lab Me to monitor your progress over time.\n",
        high_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MCHC is above the normal ranges.\n\nThis may be caused by:\n\n• Autoimmune hemolytic anemia\n• Lupus\n• Lymphoma\n• Macrocytic anemia (usually due to B-12 deficiency)\n• Hereditary spherocytosis\n• Severe burns\n• Liver disease\n• Overactive thyroid\n• Certain medications (immunosuppressive drugs, chemotherapy drugs)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 10,
        border_min: 31.7,
        normal_min: 33.4,
        normal_max: 35.5,
        border_max: 37.3,
        test_max: 50,
        range_text: "Normal range 33.4 - 35.5 grams/dL",
        low_risk_text:
          "You are at risk! Your MCHC is below the normal ranges.\n\nThe most common cause of low MCHC is anemia. Hypochromic microcytic anemia commonly results in low MCHC. This condition means your red blood cells are smaller than usual and have a decreased level of hemoglobin.\n\nThis type of microcytic anemia can be caused by:\n\n• Lack of iron\n• Inability of your body to absorb iron, which can be caused by conditions like celiac disease, Crohn’s disease, and gastric bypass surgery\n• Chronic low-grade blood loss over time from a long menstrual cycle or peptic ulcers\n• Hemolysis, or the premature destruction of red blood cells over time\n• Copper deficiency\n• Zinc excess, which causes copper deficiency\n• Alcohol use\n• Drug use\n\nIn more rare cases, low MCHC and hypochromic microcytic anemia can be caused by:\n\n• Cancer, including cancers that cause internal blood loss\n• Parasitic infections like hookworm infections\n• Lead poisoning\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MCHC is within the normal ranges. Keep using Lab Me to monitor your progress over time.\n",
        high_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MCHC is above the normal ranges.\n\nThis may be caused by:\n\n• Autoimmune hemolytic anemia\n• Lupus\n• Lymphoma\n• Macrocytic anemia (usually due to B-12 deficiency)\n• Hereditary spherocytosis\n• Severe burns\n• Liver disease\n• Overactive thyroid\n• Certain medications (immunosuppressive drugs, chemotherapy drugs)\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  mcv: {
    category: "Stress & Immune Function",
    name: "MCV",
    long_name: "Stress & Immune Function",
    uom: "fL/red cell",
    description:
      "Mean corpuscular volume (MCV) is the average volume of red blood cells in a specimen. MCV is elevated or decreased in accordance with average red cell size; for example, low MCV indicates microcytic (small average RBC size), normal MCV indicates normocytic (normal average RBC size), and high MCV indicates macrocytic (large average RBC size).",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 50,
        border_min: 76,
        normal_min: 80,
        normal_max: 96,
        border_max: 100.8,
        test_max: 200,
        range_text: "Normal range 80 - 96 fL/red cell",
        low_risk_text:
          "You are at risk! Your MCV is below the normal ranges.\n\nThis can be caused by:\n\n• Iron deficiency anemia\n• Anemia of chronic disease\n• Thalassemia\n• Sideroblastic anemia\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time",
        low_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MCV is within the normal ranges! Keep using Lab Me to monitor your progress over time.\n",
        high_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MCV is above the normal ranges. \n\nThis can be caused by:\n\n• Folate deficiency anemia\n• Vitamin B12 deficiency anemia\n• Liver disease\n• Hemolytic anemias\n• Hypothyroidism\n• Excessive alcohol intake\n• Aplastic anemia\n• Myelodysplastic syndrome\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 50,
        border_min: 76,
        normal_min: 80,
        normal_max: 96,
        border_max: 100.8,
        test_max: 200,
        range_text: "Normal range 80 - 96 fL/red cell",
        low_risk_text:
          "You are at risk! Your MCV is below the normal ranges.\n\nThis can be caused by:\n\n• Iron deficiency anemia\n• Anemia of chronic disease\n• Thalassemia\n• Sideroblastic anemia\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time",
        low_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your MCV is within the normal ranges! Keep using Lab Me to monitor your progress over time.\n",
        high_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your MCV is above the normal ranges. \n\nThis can be caused by:\n\n• Folate deficiency anemia\n• Vitamin B12 deficiency anemia\n• Liver disease\n• Hemolytic anemias\n• Hypothyroidism\n• Excessive alcohol intake\n• Aplastic anemia\n• Myelodysplastic syndrome\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  rbc: {
    category: "Stress & Immune Function",
    name: "RBC",
    long_name: "Stress & Immune Function",
    uom: "trillion cells/mcL",
    description:
      "Red blood cells (RBCs) play an important role in your health by carrying fresh oxygen throughout the body. Problems with red blood cells can be caused by illnesses or a lack of iron or vitamins in your diet. RBCs typically make up about 40% of the blood volume. RBCs contain hemoglobin, a protein that binds to oxygen and enables RBCs to carry oxygen from the lungs to the tissues and organs of the body.\n",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 4.1,
        normal_min: 4.35,
        normal_max: 5.65,
        border_max: 5.9,
        test_max: 15,
        range_text: "Normal range 4.35 - 5.65 trillion cells/mcL",
        low_risk_text:
          "You are at risk! We have detected your RBC’s are lower than normal. This condition is called anemia. Too few RBCs can affect the amount of oxygen reaching the tissues.\n\nAnemia can be caused by:\n\nTrauma\nSudden (acute) or chronic bleeding\nNutritional deficiency such as iron deficiency or vitamin B12 or folate deficiency\nChronic inflammatory disease or condition\nBone marrow damage (e.g., toxin, radiation or specific chemotherapy, infection)\nBone marrow disorders such as leukemia, multiple myeloma, lymphoma or other cancers that spread to the marrow\nKidney failure \n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n\n",
        low_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me Analytics to monitor your progress over time.\n",
        normal_text:
          "Your RBC’s levels are within the normal ranges! Keep using Lab Me to monitor your progress over time.\n\n\n",
        high_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your RBC's levels are higher than normal. Too many RBC can be due to one or more of the following:\n\n• Smoking\n• Dehydration—as the volume of fluid in the blood drops, the count of RBCs per volume of fluid artificially rises\n• Lung (pulmonary) disease—if someone is unable to breathe in and absorb sufficient oxygen, the body tries to compensate by producing more red blood cells\n• Congenital heart disease—with this condition, the heart is not able to pump blood efficiently, resulting in a decreased amount of oxygen getting to tissues. The body tries to compensate by producing more red blood cells\n• Genetic causes (altered oxygen sensing, abnormality in hemoglobin oxygen release)\n• Kidney tumor\n\nIn addition to the full CBC, some other tests may be performed at the same time or as follow up to help establish a diagnosis.  We recommend you follow up immediately with your MD to get a clearer picture - if you haven’t already done so.\n\nKeep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 0,
        border_min: 3.7,
        normal_min: 3.92,
        normal_max: 5.13,
        border_max: 5.4,
        test_max: 15,
        range_text: "Normal range 3.92 - 5.13 cells/mcL",
        low_risk_text:
          "You are at risk! We have detected your RBC’s are lower than normal. This condition is called anemia. Too few RBCs can affect the amount of oxygen reaching the tissues.\n\nAnemia can be caused by:\n\nTrauma\nSudden (acute) or chronic bleeding\nNutritional deficiency such as iron deficiency or vitamin B12 or folate deficiency\nChronic inflammatory disease or condition\nBone marrow damage (e.g., toxin, radiation or specific chemotherapy, infection)\nBone marrow disorders such as leukemia, multiple myeloma, lymphoma or other cancers that spread to the marrow\nKidney failure \n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n\n",
        low_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me Analytics to monitor your progress over time.\n",
        normal_text:
          "Your RBC’s levels are within the normal ranges! Keep using Lab Me to monitor your progress over time.\n\n\n",
        high_borderline_text:
          "Attention!\n\nYou are at risk for being out of normal ranges.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your RBC's levels are higher than normal. Too many RBC can be due to one or more of the following:\n\n• Smoking\n• Dehydration—as the volume of fluid in the blood drops, the count of RBCs per volume of fluid artificially rises\n• Lung (pulmonary) disease—if someone is unable to breathe in and absorb sufficient oxygen, the body tries to compensate by producing more red blood cells\n• Congenital heart disease—with this condition, the heart is not able to pump blood efficiently, resulting in a decreased amount of oxygen getting to tissues. The body tries to compensate by producing more red blood cells\n• Genetic causes (altered oxygen sensing, abnormality in hemoglobin oxygen release)\n• Kidney tumor\n\nIn addition to the full CBC, some other tests may be performed at the same time or as follow up to help establish a diagnosis.  We recommend you follow up immediately with your MD to get a clearer picture - if you haven’t already done so.\n\nKeep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  psa: {
    category: "Stress & Immune Function",
    name: "PSA",
    long_name: "Stress & Immune Function",
    uom: "ng/mL",
    description:
      "The Prostate Specific Antigen (PSA) blood test measures the level of PSA in a man's blood. PSA levels under 4 ng/mL are generally normal for a healthy male. According to published studies, PSA levels between 4 and 10 result in a 25% chance of prostate cancer and a PSA result greater than 10 increases the risk of cancer to 67%. While it is far from a definitive test - rising levels of PSA over time are highly indicative of prostate cancer and consequently periodic testing is recommended.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0.5,
        border_min: 1,
        normal_min: 1,
        normal_max: 4,
        border_max: 4.2,
        test_max: 100,
        range_text: "Normal range 1 - 4 ng/mL",
        low_risk_text:
          "When total prostate-specific antigen (PSA) concentration is below 2.0 ng/mL, the probability of prostate cancer in asymptomatic men is low.\n",
        low_borderline_text:
          "When total prostate-specific antigen (PSA) concentration is below 2.0 ng/mL, the probability of prostate cancer in asymptomatic men is low\n",
        normal_text:
          "Your PSA is within the optimal ranges! Tracking this overtime can help you spot significant rises that might indicate an underlying issue.",
        high_borderline_text:
          "Your PSA is borderline high. According to published studies, PSA levels between 4 and 10 result in a 25% chance of prostate cancer. Tracking this overtime can help you spot significant rises that might indicate an underlying issue.",
        high_risk_text:
          "You are at risk! Your PSA is elevated. \n\nApart from prostate cancer, the following issues can also lead to elevated PSA:\n\n• Benign prostatic hyperplasia\n• Prostatitis\n• Urinary tract infections\n• Ejaculation - some studies suggest that some men have elevated PSA levels up to 24 hours after ejaculation.\n• Increased levels of parathyroid hormone\n• Surgical procedures\n• Prostate injury\n\nPlease consult with your MD as soon as possible to identify the cause of this. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  rdw: {
    category: "Stress & Immune Function",
    name: "RDW",
    long_name: "Stress & Immune Function",
    uom: "%",
    description:
      "The red cell distribution width (RDW) blood test measures the difference in size between the biggest and the smallest red blood cells. You need red blood cells to carry oxygen from your lungs to every part of your body. Anything outside of the normal range in red blood cell width or volume indicates a possible problem with bodily function that in turn may affect oxygen getting to various parts of your body. However, with certain diseases, you may still have a normal RDW. Normal red blood cells maintain a standard size of 6 to 8 micrometers (µm) in diameter. If cells are larger, your RDW blood test values will have higher numbers. This means you may have a nutrient deficiency, anemia, or other underlying condition. Doctors often compare RDW results with those of a mean cell volume (MCV) test. An MCV test measures the average volume of a red blood cell.\n",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 5,
        border_min: 11.2,
        normal_min: 11.8,
        normal_max: 15.6,
        border_max: 16.4,
        test_max: 30,
        range_text: "Normal range 11.8 - 15.6 %",
        low_risk_text:
          "Your RDW is below the normal ranges. \n\nAlthough low level of RDW does not have a significant clinical relevance itself, to receive a proper diagnosis, your doctor must look at other blood tests — such as the mean corpuscular volume (MCV) test, which is also part of a CBC — to combine results and provide an accurate treatment recommendation.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Your RDW is borderline low.\n\nAlthough low RDW does not have a significant clinical relevance itself, to receive a proper diagnosis, your doctor must look at other blood tests — such as the mean corpuscular volume (MCV) test, which is also part of a CBC — to combine results and provide an accurate treatment recommendation.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your RDW is within the normal ranges.  However, please check against your MVC.\n\nIf you receive a normal RDW with a low MCV, you may have an anemia resulting from a chronic disease, such as that caused by chronic kidney disease.\n\nIf your RDW result is normal but you have a high MCV, you may have aplastic anemia. This is a blood disorder in which your bone marrow doesn’t produce enough blood cells, including red blood cells.\n\nPlease consult with your MD regarding further testing. Keep using Lab Me to monitor your progress over time.\n",
        high_borderline_text:
          "Your RDW is borderline high.\n\nAlthough low RDW does not have a significant clinical relevance itself, to receive a proper diagnosis, your doctor must look at other blood tests — such as the mean corpuscular volume (MCV) test, which is also part of a CBC — to combine results and provide an accurate treatment recommendation.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your RDW is above the normal ranges.\n\nThis can be caused by:\n\n• Macrocytic anemia, when your body doesn’t produce enough normal red blood cells, and the cells it does produce are larger than normal. This can be due to a deficiency of folate or vitamin B-12.\n• Microcytic anemia, which is deficiency of normal red blood cells, and your red blood cells will be smaller than normal. Iron deficiency anemia is a common cause of microcytic anemia.\n\n\nTo help properly diagnose these conditions, you doctor will perform a CBC test and compare the RDW and MCV test portions to measure your red blood cell volume.\nA high MCV with a high RDW occurs in some macrocytic anemias. A low MCV with a high RDW occurs in microcytic anemias.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me Analytics to monitor your progress over time.\n",
      },
      female: {
        test_min: 5,
        border_min: 11.2,
        normal_min: 11.8,
        normal_max: 15.6,
        border_max: 16.4,
        test_max: 30,
        range_text: "Normal range 11.8 - 15.6 %",
        low_risk_text:
          "Your RDW is below the normal ranges. \n\nAlthough low level of RDW does not have a significant clinical relevance itself, to receive a proper diagnosis, your doctor must look at other blood tests — such as the mean corpuscular volume (MCV) test, which is also part of a CBC — to combine results and provide an accurate treatment recommendation.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Your RDW is borderline low.\n\nAlthough low RDW does not have a significant clinical relevance itself, to receive a proper diagnosis, your doctor must look at other blood tests — such as the mean corpuscular volume (MCV) test, which is also part of a CBC — to combine results and provide an accurate treatment recommendation.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your RDW is within the normal ranges.  However, please check against your MVC.\n\nIf you receive a normal RDW with a low MCV, you may have an anemia resulting from a chronic disease, such as that caused by chronic kidney disease.\n\nIf your RDW result is normal but you have a high MCV, you may have aplastic anemia. This is a blood disorder in which your bone marrow doesn’t produce enough blood cells, including red blood cells.\n\nPlease consult with your MD regarding further testing. Keep using Lab Me to monitor your progress over time.\n",
        high_borderline_text:
          "Your RDW is borderline high.\n\nAlthough low RDW does not have a significant clinical relevance itself, to receive a proper diagnosis, your doctor must look at other blood tests — such as the mean corpuscular volume (MCV) test, which is also part of a CBC — to combine results and provide an accurate treatment recommendation.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your RDW is above the normal ranges.\n\nThis can be caused by:\n\n• Macrocytic anemia, when your body doesn’t produce enough normal red blood cells, and the cells it does produce are larger than normal. This can be due to a deficiency of folate or vitamin B-12.\n• Microcytic anemia, which is deficiency of normal red blood cells, and your red blood cells will be smaller than normal. Iron deficiency anemia is a common cause of microcytic anemia.\n\n\nTo help properly diagnose these conditions, you doctor will perform a CBC test and compare the RDW and MCV test portions to measure your red blood cell volume.\nA high MCV with a high RDW occurs in some macrocytic anemias. A low MCV with a high RDW occurs in microcytic anemias.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels.  Keep using Lab Me Analytics to monitor your progress over time.\n",
      },
    },
  },
  cortisol: {
    category: "Stress & Immune Function",
    name: "Cortisol AM",
    long_name: "Stress & Immune Function",
    uom: "ug/dL",
    description:
      "Cortisol is a hormone that affects almost every organ and tissue in your body. It plays an important role in helping you to:<br/>\n<br/>\n\n\n• Respond to stress<br/>\n\n• Fight infection<br/>\n\n• Regulate blood sugar<br/>\n\n• Maintain blood pressure<br/>\n\n• Regulate metabolism, the process of how your body uses food and energy<br/>\n<br/>\n\n\nCortisol is made by your adrenal glands, two small glands located above the kidneys. A cortisol test measures the level of cortisol in your blood, urine, or saliva. <br/><br/>\n\nBlood tests are the most common way of measuring cortisol. If your cortisol levels are too high or too low, it may mean you have a disorder of your adrenal glands. These disorders can be serious if not treated. <br/><br/>\n\nAcute stress (including hospitalization and surgery), alcoholism, depression, and many drugs (eg, exogenous cortisones, anti-convulsants) can obliterate normal daily variation in cortisol levels, affect response to suppression/stimulation tests, and cause elevated baseline levels.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 3,
        border_min: 6.7,
        normal_min: 7,
        normal_max: 25,
        border_max: 26.3,
        test_max: 60,
        range_text: "Normal range 7 - 25 ug/dL",
        low_risk_text:
          "You are at risk! Your cortisol levels are low.<br/>\n<br/>\n\n\nThis may be caused by:<br/>\n<br/>\n\n\n• Primary adrenal insufficiency - Addison disease<br/>\n\n• Secondary adrenal insufficiency (pituitary insufficiency or hypothalamic insufficiency)<br/>\n\n• Congenital adrenal hyperplasia-defects in enzymes involved in cortisol synthesis<br/>\n<br/>\n\n\nWhen symptoms of glucocorticoid deficiency are present and the 8 a.m. plasma cortisol value is <10 mcg/dL, further studies are needed to establish the diagnosis. First, the basal plasma ACTH concentration should be measured, followed by the short cosyntropin stimulation test. Other frequently used tests are the metyrapone, and insulin-induced hypoglycemia test.<br/>\n<br/>\n\n\nPlease consult with your MD as soon as possible to find a way to normalize these values. Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your cortisol level is borderline low. We highly recommend contacting a MD to find out how to keep this managed before it becomes an issue.  We also would highly recommend you order a follow up test in 3 months if you haven’t already subscribed.",
        normal_text: "Your cortisol levels are within the normal ranges!",
        high_borderline_text:
          "Attention! Your cortisol level is borderline high. We highly recommend contacting a MD to find out how to keep this managed before it becomes an issue.  We also would highly recommend you order a follow up test in 3 months if you haven’t already subscribed.",
        high_risk_text:
          "You are at risk! Your cortisol levels are high.\n\nThis may be caused by:\n\n• Stress\n• Cushing syndrome\n• Pituitary gland issues\n• Adrenal gland tumors\n• Medications (oral contraceptives, corticosteroids)\n• High estrogen levels\n\nPlease consult with your MD for further testing as soon as possible. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 3,
        border_min: 6.7,
        normal_min: 7,
        normal_max: 25,
        border_max: 26.3,
        test_max: 60,
        range_text: "Normal range 7 - 25 ug/dL",
        low_risk_text:
          "You are at risk! Your cortisol levels are low.<br/>\n<br/>\n\n\nThis may be caused by:<br/>\n<br/>\n\n\n• Primary adrenal insufficiency - Addison disease<br/>\n\n• Secondary adrenal insufficiency (pituitary insufficiency or hypothalamic insufficiency)<br/>\n\n• Congenital adrenal hyperplasia-defects in enzymes involved in cortisol synthesis<br/>\n<br/>\n\n\nWhen symptoms of glucocorticoid deficiency are present and the 8 a.m. plasma cortisol value is <10 mcg/dL, further studies are needed to establish the diagnosis. First, the basal plasma ACTH concentration should be measured, followed by the short cosyntropin stimulation test. Other frequently used tests are the metyrapone, and insulin-induced hypoglycemia test.<br/>\n<br/>\n\n\nPlease consult with your MD as soon as possible to find a way to normalize these values. Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your cortisol level is borderline low. We highly recommend contacting a MD to find out how to keep this managed before it becomes an issue.  We also would highly recommend you order a follow up test in 3 months if you haven’t already subscribed.",
        normal_text: "Your cortisol levels are within the normal ranges!",
        high_borderline_text:
          "Attention! Your cortisol level is borderline high. We highly recommend contacting a MD to find out how to keep this managed before it becomes an issue.  We also would highly recommend you order a follow up test in 3 months if you haven’t already subscribed.",
        high_risk_text:
          "You are at risk! Your cortisol levels are high.\n\nThis may be caused by:\n\n• Stress\n• Cushing syndrome\n• Pituitary gland issues\n• Adrenal gland tumors\n• Medications (oral contraceptives, corticosteroids)\n• High estrogen levels\n\nPlease consult with your MD for further testing as soon as possible. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  monocyte_count: {
    category: "Stress & Immune Function",
    name: "Monocyte Count",
    long_name: "Stress & Immune Function",
    uom: "cells/uL",
    description:
      "Monocytes are a type of white blood cell. They help fight bacteria, viruses, and other infections in your body. Your bone marrow produces monocytes and releases them into your bloodstream. Once they reach tissues in your body, they’re called macrophages. There, they isolate and gobble up germs and other harmful microorganisms. They also get rid of dead cells and assist in the immune response.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 0.2,
        normal_min: 0.2,
        normal_max: 1,
        border_max: 1.1,
        test_max: 10,
        range_text: "Normal range 0.2 - 1 cells/uL",
        low_risk_text:
          "You are at risk! Your monocyte count is lower than the normal ranges.\n\nA decrease in monocytes may be caused by:\n\n• bloodstream infections\n• skin infections\n• chemotherapy treatments\n• bone marrow disorders\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your monocyte count is borderline low.  Try the following for the next month and test again.\nYou can change the frequency of your test to a month from your subscription dashboard.\n\nYour immune system may not work as it should and you are at higher risk for infection and becoming sick.\n\nWhat you can do over the coming month:\n\nYou can consider increasing the protein  in your diet.\nHere is a great article on other suggestions you can do to boost your white blood cells.\n\nOf interest, many people try to supplement with iron.  Iron only affects Red Blood Cells and will not impact your White Blood Cell count.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your monocyte count is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your monocyte count is borderline high.  Try the following for the next month and test again.\nYou can change the frequency of your test to a month from your subscription dashboard.\n\nVitamin C. Eating Vitamin C will help regulate the levels of white blood cells in your body. Fruits like lemons, oranges, and lime are rich in vitamin C, and so are papayas, berries, guavas, and pineapples. You can also get vitamin C from vegetables such as cauliflower, broccoli, carrots, and bell peppers.\n\nAntioxidants. Antioxidants are chemicals that neutralize harmful molecules called free radicals. These free radicals can damage protein, cells, and DNA, but antioxidants can eliminate them, which is why they are also called ‘free radical scavengers’. You may consider adding leeks, onions, garlic, tea, grapes, and other fruits and veggies to your diet to provide your body with antioxidants that support a healthy immune system.\n\nOmega-3 Fatty Acids. Your body cannot make these essential fatty acids, so you have to get them through food. It improves cardiovascular health and elevates the activity of phagocytes, a specific type of white blood cells that fight off foreign bacteria. This polyunsaturated fat is available in fatty fish like herring, trout, and salmon, as well as in flaxseed and walnuts.\n\nAvoid foods rich in sugar, fat and salt. You can replace them with any food that lowers inflammation to reduce white blood cell count, like grapes, garlic, spices, nuts, soy protein, vinegar, and black and green teas.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your monocyte count is higher than the normal ranges.\n\nCommon conditions that could lead to a spike in abs monocytes include:\n\nSarcoidosis, a disease in which abnormal levels of inflammatory cells gather in multiple Organs of the body\nChronic inflammatory diseases, such as inflammatory bowel disease\nLeukemia and other types of cancer, including lymphoma and multiple myeloma\nAutoimmune diseases, such as lupus and rheumatoid arthritis\n\n\nInterestingly, low levels of monocytes can be the result of autoimmune diseases, too.\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
      },
      female: {
        test_min: 0,
        border_min: 0.2,
        normal_min: 0.2,
        normal_max: 1,
        border_max: 1.1,
        test_max: 10,
        range_text: "Normal range 0.2 - 1 cells/uL",
        low_risk_text:
          "You are at risk! Your monocyte count is lower than the normal ranges.\n\nA decrease in monocytes may be caused by:\n\n• bloodstream infections\n• skin infections\n• chemotherapy treatments\n• bone marrow disorders\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your monocyte count is borderline low.  Try the following for the next month and test again.\nYou can change the frequency of your test to a month from your subscription dashboard.\n\nYour immune system may not work as it should and you are at higher risk for infection and becoming sick.\n\nWhat you can do over the coming month:\n\nYou can consider increasing the protein  in your diet.\nHere is a great article on other suggestions you can do to boost your white blood cells.\n\nOf interest, many people try to supplement with iron.  Iron only affects Red Blood Cells and will not impact your White Blood Cell count.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your monocyte count is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your monocyte count is borderline high.  Try the following for the next month and test again.\nYou can change the frequency of your test to a month from your subscription dashboard.\n\nVitamin C. Eating Vitamin C will help regulate the levels of white blood cells in your body. Fruits like lemons, oranges, and lime are rich in vitamin C, and so are papayas, berries, guavas, and pineapples. You can also get vitamin C from vegetables such as cauliflower, broccoli, carrots, and bell peppers.\n\nAntioxidants. Antioxidants are chemicals that neutralize harmful molecules called free radicals. These free radicals can damage protein, cells, and DNA, but antioxidants can eliminate them, which is why they are also called ‘free radical scavengers’. You may consider adding leeks, onions, garlic, tea, grapes, and other fruits and veggies to your diet to provide your body with antioxidants that support a healthy immune system.\n\nOmega-3 Fatty Acids. Your body cannot make these essential fatty acids, so you have to get them through food. It improves cardiovascular health and elevates the activity of phagocytes, a specific type of white blood cells that fight off foreign bacteria. This polyunsaturated fat is available in fatty fish like herring, trout, and salmon, as well as in flaxseed and walnuts.\n\nAvoid foods rich in sugar, fat and salt. You can replace them with any food that lowers inflammation to reduce white blood cell count, like grapes, garlic, spices, nuts, soy protein, vinegar, and black and green teas.\n\nPlease consult with your medical provider as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your monocyte count is higher than the normal ranges.\n\nCommon conditions that could lead to a spike in abs monocytes include:\n\nSarcoidosis, a disease in which abnormal levels of inflammatory cells gather in multiple Organs of the body\nChronic inflammatory diseases, such as inflammatory bowel disease\nLeukemia and other types of cancer, including lymphoma and multiple myeloma\nAutoimmune diseases, such as lupus and rheumatoid arthritis\n\n\nInterestingly, low levels of monocytes can be the result of autoimmune diseases, too.\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
      },
    },
  },
  lymphocyte_count: {
    category: "Stress & Immune Function",
    name: "Lymphocyte Count",
    long_name: "Stress & Immune Function",
    uom: "cells/uL",
    description:
      "Lymphocytes are cells that circulate in your blood that are part of the immune system. There are two main types lymphocytes: T cells and B cells. B cells produce antibody molecules that can latch on and destroy invading viruses or bacteria. T cells are direct fighters of foreign invaders and also produced cytokines, which are biological substances that help activate other parts of the immune system.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 1,
        normal_min: 1,
        normal_max: 3,
        border_max: 3.2,
        test_max: 10,
        range_text: "Normal range 1 - 3 cells/uL",
        low_risk_text:
          "You are at risk! Your lymphocyte count is lower than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chemotherapy\n• HIV/AIDS infection\n• Leukemia\n• Radiation therapy or exposure\n• Sepsis (severe, inflammatory response to bacteria or other germs)\n• Steroid use\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your lymphocyte count is borderline low.\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your lymphocyte count is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your lymphocyte count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your lymphocyte count is higher than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chronic bacterial infection\n• Infectious hepatitis\n• Infectious mononucleosis, or mono\n• Lymphocytic leukemia\n• Multiple myeloma\n• Viral infection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 1,
        normal_min: 1,
        normal_max: 3,
        border_max: 3.2,
        test_max: 10,
        range_text: "Normal range 1 - 3 cells/uL",
        low_risk_text:
          "You are at risk! Your lymphocyte count is lower than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chemotherapy\n• HIV/AIDS infection\n• Leukemia\n• Radiation therapy or exposure\n• Sepsis (severe, inflammatory response to bacteria or other germs)\n• Steroid use\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your lymphocyte count is borderline low.\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your lymphocyte count is within the normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your lymphocyte count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your lymphocyte count is higher than the normal ranges.\n\nA decrease in lymphocytes may be caused by:\n\n• Chronic bacterial infection\n• Infectious hepatitis\n• Infectious mononucleosis, or mono\n• Lymphocytic leukemia\n• Multiple myeloma\n• Viral infection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  basophil_count: {
    category: "Stress & Immune Function",
    name: "Basophil Count",
    long_name: "Stress & Immune Function",
    uom: "cells/uL",
    description:
      "Basophils are the least common of the granulocytes, representing about 0.01% to 0.3% of circulating leukocytes (white blood cells). If basophil levels are low, this may be a sign of an allergic reaction or another condition. High basophil levels may indicate an autoimmune condition or one of several types of blood disorder.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 0.02,
        normal_min: 0.02,
        normal_max: 0.1,
        border_max: 0.1,
        test_max: 5,
        range_text: "Normal range 0.02 - 0.1 cells/uL",
        low_risk_text:
          "You are at risk! Your basophils count is lower than the normal ranges. \n\nA decrease in basophils may be caused by:\n\n• Acute allergic reaction.\n• Infections\n• Hyperthyroidism (when a thyroid gland produces too much thyroid hormone)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your basophil count is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your basophil count is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your basophil count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your basophil count is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• Rheumatoid arthritis\n• Lupus\n• Inflammatory bowel diseases, such as Crohn’s disease or ulcerative colitis\n• Intestinal parasites\n• Diabetes\n• Allergies and asthma\n• Hypothyroidism\n• Myeloproliferative disorders (disorders that affect the bone marrow)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time\n",
      },
      female: {
        test_min: 0,
        border_min: 0.02,
        normal_min: 0.02,
        normal_max: 0.1,
        border_max: 0.1,
        test_max: 5,
        range_text: "Normal range 0.02 - 0.1 cells/uL",
        low_risk_text:
          "You are at risk! Your basophils count is lower than the normal ranges. \n\nA decrease in basophils may be caused by:\n\n• Acute allergic reaction.\n• Infections\n• Hyperthyroidism (when a thyroid gland produces too much thyroid hormone)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
        low_borderline_text:
          "Attention! Your basophil count is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your basophil count is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n\n",
        high_borderline_text:
          "Attention! Your basophil count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your basophil count is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• Rheumatoid arthritis\n• Lupus\n• Inflammatory bowel diseases, such as Crohn’s disease or ulcerative colitis\n• Intestinal parasites\n• Diabetes\n• Allergies and asthma\n• Hypothyroidism\n• Myeloproliferative disorders (disorders that affect the bone marrow)\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time\n",
      },
    },
  },
  eosinophil_count: {
    category: "Stress & Immune Function",
    name: "Eosinophil Count",
    long_name: "Stress & Immune Function",
    uom: "cells/uL",
    description:
      "The eosinophil is a specialized cell of the immune system. This proinflammatory white blood cell generally has a nucleus with two lobes (bilobed) and cytoplasm filled with approximately 200 large granules containing enzymes and proteins with different (known and unknown) functions. The functions of the eosinophil are varied, some of which are very similar to other white blood cells. They are implicated in numerous inflammatory processes, especially allergic disorders.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 0.02,
        normal_min: 0.02,
        normal_max: 0.5,
        border_max: 0.5,
        test_max: 5,
        range_text: "Normal range 0.02 - 0.5 cells/uL",
        low_risk_text:
          "You are at risk! Your eosinophil count is lower than the normal ranges. \n\nA decrease in basophils may be a sign of:\n\n• An intoxication from alcohol\n• Excessive production of cortisol, like in Cushing’s disease\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your eosinophil count is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your eosinophil count is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your eosinophil count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your eosinophil count is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• An infection by parasitic worms\n• An autoimmune disease\n• Severe allergic reactions\n• Eczema\n• Asthma\n• Seasonal allergies\n• Leukemia and certain other cancers\n• Ulcerative colitis\n• Scarlet fever\n• Lupus\n• Crohn’s disease\n• A significant drug reaction\n• An organ transplant rejection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 0.02,
        normal_min: 0.02,
        normal_max: 0.5,
        border_max: 0.5,
        test_max: 5,
        range_text: "Normal range 0.02 - 0.5 cells/uL",
        low_risk_text:
          "You are at risk! Your eosinophil count is lower than the normal ranges. \n\nA decrease in basophils may be a sign of:\n\n• An intoxication from alcohol\n• Excessive production of cortisol, like in Cushing’s disease\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your eosinophil count is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your eosinophil count is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your eosinophil count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your eosinophil count is higher than the normal ranges. \n\nAn increase in basophils may be caused by:\n\n• An infection by parasitic worms\n• An autoimmune disease\n• Severe allergic reactions\n• Eczema\n• Asthma\n• Seasonal allergies\n• Leukemia and certain other cancers\n• Ulcerative colitis\n• Scarlet fever\n• Lupus\n• Crohn’s disease\n• A significant drug reaction\n• An organ transplant rejection\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
  neutrophil_count: {
    category: "Stress & Immune Function",
    name: "Neutrophil Count",
    long_name: "Stress & Immune Function",
    uom: "cells/uL",
    description:
      "Neutrophils are a type of white blood cell that helps heal damaged tissues and resolve infections. Neutrophils help prevent infections by blocking, disabling, digesting, or warding off invading particles and microorganisms. They also communicate with other cells to help them repair cells and mount a proper immune response.",
    isEnable: true,
    range: {
      age: "all",
      male: {
        test_min: 0,
        border_min: 1.9,
        normal_min: 2,
        normal_max: 7,
        border_max: 7.4,
        test_max: 15,
        range_text: "Normal range 2 - 7 cells/uL",
        low_risk_text:
          "You are at risk! Your neutrophil count is lower than the normal ranges. \n\nA decrease in neutrophil may be caused by:\n\n• Severe or chronic bacterial infections\n• Allergic disorders\n• Certain drug treatments (phenytoin and sulfa drugs)\n• Autoimmune disorders\n• Cancer\n• Viral infections, such as influenza\n• Bacteria infections, such as tuberculosis\n• Myelofibrosis, a disorder that involves bone marrow scarring\n• Vitamin B-12 deficiency\n• Radiation therapy involving bone marrow\n• Chemotherapy medications\n• Toxins, such as benzenes and insecticides\n• Aplastic anemia, when the bone marrow stops producing enough blood cells\n• Severe congenital neutropenia, a group of disorders where neutrophils cannot mature\n• Cyclic neutropenia, which causes cell levels to rise and fall\n• Chronic benign neutropenia, which causes low cell levels for no apparent reason\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your neutrophil count is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your neutrophil count is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your neutrophil count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your neutrophil count is higher than the normal ranges. \n\nAn increase in neutrophil may be caused by:\n\n• Infections\n• Injuries\n• Some medications, such as corticosteroids, beta-2-agonists, and epinephrine\n• Some cancers\n• Physical or emotional stress\n• Surgery or accidents\n• Smoking tobacco\n• Pregnancy\n• Obesity\n• Genetic conditions, such as Down syndrome\n• Surgical removal of the spleen\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
      female: {
        test_min: 0,
        border_min: 1.9,
        normal_min: 2,
        normal_max: 7,
        border_max: 7.4,
        test_max: 15,
        range_text: "Normal range 2 - 7 cells/uL",
        low_risk_text:
          "You are at risk! Your neutrophil count is lower than the normal ranges. \n\nA decrease in neutrophil may be caused by:\n\n• Severe or chronic bacterial infections\n• Allergic disorders\n• Certain drug treatments (phenytoin and sulfa drugs)\n• Autoimmune disorders\n• Cancer\n• Viral infections, such as influenza\n• Bacteria infections, such as tuberculosis\n• Myelofibrosis, a disorder that involves bone marrow scarring\n• Vitamin B-12 deficiency\n• Radiation therapy involving bone marrow\n• Chemotherapy medications\n• Toxins, such as benzenes and insecticides\n• Aplastic anemia, when the bone marrow stops producing enough blood cells\n• Severe congenital neutropenia, a group of disorders where neutrophils cannot mature\n• Cyclic neutropenia, which causes cell levels to rise and fall\n• Chronic benign neutropenia, which causes low cell levels for no apparent reason\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.\n",
        low_borderline_text:
          "Attention! Your neutrophil count is borderline low.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        normal_text:
          "Your neutrophil count is within normal ranges! Keep using Lab Me to ensure you stay within these ranges over time.\n",
        high_borderline_text:
          "Attention! Your neutrophil count is borderline high.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels.  Keep using Lab Me to monitor your progress over time.\n",
        high_risk_text:
          "You are at risk! Your neutrophil count is higher than the normal ranges. \n\nAn increase in neutrophil may be caused by:\n\n• Infections\n• Injuries\n• Some medications, such as corticosteroids, beta-2-agonists, and epinephrine\n• Some cancers\n• Physical or emotional stress\n• Surgery or accidents\n• Smoking tobacco\n• Pregnancy\n• Obesity\n• Genetic conditions, such as Down syndrome\n• Surgical removal of the spleen\n\nIf results indicate a problem, a wide variety of other tests may be performed in order to help determine the cause.\n\nPlease consult with your MD as soon as possible to find out how to normalize these levels. Keep using Lab Me to monitor your progress over time.",
      },
    },
  },
};
