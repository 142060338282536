import { productDetails as prod_product } from "../data/prod_products.js";
import { productDetails as beta_product } from "../data/products.js";

let products_ = null;
if (process.env.REACT_APP_STAGE === 'prod') {
    products_ = prod_product;
} else {
    products_ = beta_product;
}
export const products = products_;

let pixelId_ = null;
if (process.env.REACT_APP_STAGE === 'prod') {
    pixelId_ = '801091216686465';
} else {
    pixelId_ = '2358894594192051';
}
export const pixelId = pixelId_;