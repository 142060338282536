export const productDetails = {
    "prod_JTjTzjpOc7KGfx": {
        name: "VitalOne At Home Blood Test",
        descriptions: "The VitalOne at-home comprehensive test helps monitor for cardiovascular disease, ferritin levels, cortisol, stress, unhealthy inflammation, & vitamin D. Track your health from the inside out with VitalOne's exclusive at-home blood test.",
        id: "prod_JTjTzjpOc7KGfx",
        imageUrl: "https://d1wqzb5bdbcre6.cloudfront.net/6b115870bdcd07a3661fca461348fac9581fcab91b30e83b1484587f62f7a45e/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a644638785245707a5346704b626e564b544567324f446c4b66475a7358327870646d5666636e5a4b62456442566a4d79535539455455673152445631595446336455524b30305a4372554f335132",
        sku: "2018",
        prices: {
            "onetime": {
                name: "one time",
                price: "$129.00",
                id: "price_1Iqm0zJnuJLH689JB7s33O0B"
            },
            "quarterly": {
                name: "one time",
                price: "$116.00",
                id: "price_1IscFOJnuJLH689J4zY0aUxG"
            },
            "biyearly": {
                name: "one time",
                price: "$122.00",
                id: "price_1IscFwJnuJLH689JYExq1BwU"
            }
        }
    },
    "prod_IQHjboxntfX0Jv": {
        name: "Vitamin D",
        descriptions: "At home testing Vitamin D.",
        id: "prod_IQHjboxntfX0Jv",
        imageUrl: "https://stripe-camo.global.ssl.fastly.net/783a8202760d9b0b4ac52399c064376df3814a43/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f666c5f6c6976655f336e62325537426f754276466b4356567a466e4c4a636d30",
        sku: "2997",
        prices: {
            "onetime": {
                name: "one time",
                price: "$89.00",
                id: "price_1HpRABJnuJLH689JlUchV72H"
            },
            "quarterly": {
                name: "quarterly",
                price: "$80.00",
                id: "price_1IscRKJnuJLH689JsqHlZZ56"
            },
            "biyearly": {
                name: "biyearly",
                price: "$84.00",
                id: "price_1IscRhJnuJLH689JJNxMW60P"
            }
        }
    },
    "prod_IMmxCsX0bEVyfG": {
        name: "Complete Blood Count",
        descriptions: "CBC with differential.",
        id: "prod_IMmxCsX0bEVyfG",
        imageUrl: "https://d1wqzb5bdbcre6.cloudfront.net/1afdd1e3c7bfe734182b72a891194ef41353b857/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f666c5f6c6976655f67335a4a7435617a7a435534737838456371465443745937",
        sku: "4843",
        prices: {
            "onetime": {
                name: "one time",
                price: "$129.00",
                id: "price_1ItSodJnuJLH689Jv4GRJZN6"
            },
            "quarterly": {
                name: "quarterly",
                price: "$89.00",
                id: "price_1IscSxJnuJLH689JwwmbE0GQ"
            },
            "biyearly": {
                name: "biyearly",
                price: "$94.00",
                id: "price_1IscTGJnuJLH689JhTFwVsRP"
            }
        }
    },
    "prod_ILowfDzjlE8dg9": {
        name: "Testosterone",
        descriptions: "Our home testosterone test measures the amount of testosterone present in your blood.",
        id: "prod_ILowfDzjlE8dg9",
        imageUrl: "https://files.stripe.com/links/fl_live_CtWxkC8LPWNvfX18YNeE7CTy",
        sku: "2984",
        prices: {
            "onetime": {
                name: "one time",
                price: "$65.00",
                id: "price_1Hl7IOJnuJLH689J6E5hBKn9"
            },
            "quarterly": {
                name: "quarterly",
                price: "$58.00",
                id: "price_1IscUbJnuJLH689J0DWxVC8u"
            },
            "biyearly": {
                name: "biyearly",
                price: "$61.00",
                id: "price_1IscUsJnuJLH689J5e77JgbB"
            }
        }
    },
    "prod_ILpGeskin5GG54": {
        name: "Cortisol",
        descriptions: "Our home cortisol test measures the amount of cortisol present in your blood.",
        id: "prod_ILpGeskin5GG54",
        imageUrl: "https://files.stripe.com/links/fl_live_CmE7nnmTgPMXKFzbQi4LyKVZ",
        sku: "2978",
        prices: {
            "onetime": {
                name: "one time",
                price: "$82.00",
                id: "price_1Hl7cBJnuJLH689JkfwYmzfi"
            },
            "quarterly": {
                name: "quarterly",
                price: "$73.00",
                id: "price_1IscVrJnuJLH689JTiX42hI6"
            },
            "biyearly": {
                name: "biyearly",
                price: "$77.00",
                id: "price_1IscW7JnuJLH689J3NIZHO5i"
            }
        }
    },
    "prod_ILpOO8s0W7uz8h": {
        name: "Thyroid Stimulating Hormone",
        descriptions: "Our home TSH test measures the amount of TSH present in your blood.",
        id: "prod_ILpOO8s0W7uz8h",
        imageUrl: "https://files.stripe.com/links/fl_live_hLETW2GLERi00cFPSsGF0PpB",
        sku: "2985",
        prices: {
            "onetime": {
                name: "one time",
                price: "$79.00",
                id: "price_1Hl7jkJnuJLH689JwVpGWvi2"
            },
            "quarterly": {
                name: "quarterly",
                price: "$71.00",
                id: "price_1IscXCJnuJLH689JVFpOoo6u"
            },
            "biyearly": {
                name: "biyearly",
                price: "$75.00",
                id: "price_1IscXSJnuJLH689JwBWnQf6u"
            }
        }
    },
    "prod_ILpkjPcDUSyx2T": {
        name: "Ferritin",
        descriptions: "Measures the total amount of ferritin in your body. Online results 48 hours after lab receives test kit.",
        id: "prod_ILpkjPcDUSyx2T",
        imageUrl: "https://files.stripe.com/links/fl_live_pPIt1pTd8gcDVbYjhi7TLifM",
        sku: "2977",
        prices: {
            "onetime": {
                name: "one time",
                price: "$59.00",
                id: "price_1Hl85PJnuJLH689JN0JvTx5F"
            },
            "quarterly": {
                name: "quarterly",
                price: "$53.00",
                id: "price_1IscYJJnuJLH689JsUStrfVb"
            },
            "biyearly": {
                name: "biyearly",
                price: "$56.00",
                id: "price_1IscYdJnuJLH689Jy69SOQYp"
            }
        }
    },
    "prod_ILpqKd6KkM5f7P": {
        name: "hsCRP",
        descriptions: "At home CRP test to identify inflammation in your body with online results in 2 days after lab receives your test.",
        id: "prod_ILpqKd6KkM5f7P",
        imageUrl: "https://files.stripe.com/links/fl_live_SeiVrR2v0DEu4pSdeeQWkOYi",
        sku: "2982",
        prices: {
            "onetime": {
                name: "one time",
                price: "$59.00",
                id: "price_1Hl8ArJnuJLH689JcGVhQfZQ"
            },
            "quarterly": {
                name: "quarterly",
                price: "$53.00",
                id: "price_1IscZmJnuJLH689J38VR6ejm"
            },
            "biyearly": {
                name: "biyearly",
                price: "$56.00",
                id: "price_1IscZzJnuJLH689JaylIX8vt"
            }
        }
    },
    "prod_IDuUluWl8RGH8p": {
        name: "Men's Executive Wellness Check",
        descriptions: "Cardiometabolic, Liver, Kidneys, Cortisol, Vitamin D, PSA, Testosterone.",
        id: "prod_IDuUluWl8RGH8p",
        imageUrl: "https://files.stripe.com/links/fl_live_8JQ2BKtJ89WgPVlBG5XS1bOz",
        sku: "2011",
        prices: {
            "onetime": {
                name: "one time",
                price: "$159.00",
                id: "price_1Hgk8iJnuJLH689J2wPFbFih"
            },
            "quarterly": {
                name: "quarterly",
                price: "$143.00",
                id: "price_1IscbIJnuJLH689Js6N6G7Ca"
            },
            "biyearly": {
                name: "biyearly",
                price: "$151.00",
                id: "price_1IscbYJnuJLH689JjGcqW4hM"
            }
        }
    },
    "prod_IDuXUlWLtSRSi6": {
        name: "Women's Executive Health Check",
        descriptions: "Cardiometabolic, Liver, Kidneys, TSH, Vitamin D, Cortisol.",
        id: "prod_IDuXUlWLtSRSi6",
        imageUrl: "https://files.stripe.com/links/fl_live_AlhuuBttuoeoomF0LBlpcOZ4",
        sku: "2010",
        prices: {
            "onetime": {
                name: "one time",
                price: "$149.00",
                id: "price_1HgkByJnuJLH689JgwvrGvMb"
            },
            "quarterly": {
                name: "quarterly",
                price: "$134.00",
                id: "price_1IscciJnuJLH689J0O31dNVG"
            },
            "biyearly": {
                name: "biyearly",
                price: "$141.00",
                id: "price_1IsccwJnuJLH689Jb5a4lv2w"
            }
        }
    },
    "prod_I4CC6iyd3DxIki": {
        name: "Crucial Health Check",
        descriptions: "The Crucial Health Check is a simple, at-home finger-prick blood test which checks your liver and heart health. Useful for early detection or monitoring of issues that could lead to heart or liver failure.",
        id: "prod_I4CC6iyd3DxIki",
        imageUrl: "https://files.stripe.com/links/fl_test_OKNo3qNznS3JX3aoUTxw5n3a",
        sku: "2863",
        prices: {
            "onetime": {
                name: "one time",
                price: "$70.00",
                id: "price_1Hl6fZJnuJLH689JW7U4KSw0"
            },
            "quarterly": {
                name: "quarterly",
                price: "$63.00",
                id: "price_1IscfUJnuJLH689JGSQQdvTz"
            },
            "biyearly": {
                name: "biyearly",
                price: "$66.00",
                id: "price_1IscfiJnuJLH689JFAP1nkB2"
            }
        }
    },
    "prod_I4CBnStP1KIQRY": {
        name: "Baseline Health Check",
        descriptions: "The Baseline Health Check is a simple, at-home, finger -prick blood test which checks your liver and kidney function, heart health, and risk for diabetes, heart attack, and stroke.",
        id: "prod_I4CBnStP1KIQRY",
        imageUrl: "https://files.stripe.com/links/fl_test_ARgqFVy3ssP4zqRe4O6hFsa5",
        sku: "2864",
        prices: {
            "onetime": {
                name: "one time",
                price: "$99.00",
                id: "price_1Hl6k9JnuJLH689JilR4xYrM"
            },
            "quarterly": {
                name: "quarterly",
                price: "$89.00",
                id: "price_1IschCJnuJLH689JporYCgDj"
            },
            "biyearly": {
                name: "biyearly",
                price: "$94.00",
                id: "price_1IscgyJnuJLH689Jtxb5CROu"
            }
        }
    },
    "prod_I4CAQhvzPW0fEJ": {
        name: "Executive Health Check",
        descriptions: "With the Executive Health Check you get highly accurate results for your liver and kidney function, heart health, inflammation, Vitamin D and the stress marker cortisol.",
        id: "prod_I4CAQhvzPW0fEJ",
        imageUrl: "https://files.stripe.com/links/fl_test_5GPOxxOYOzDJ61zDHIraKZWd",
        sku: "2865",
        prices: {
            "onetime": {
                name: "one time",
                price: "$129.00",
                id: "price_1HU3lsJnuJLH689JfjUg4qiD"
            },
            "quarterly": {
                name: "quarterly",
                price: "$107.00",
                id: "price_1IsciRJnuJLH689J9wQHfGUs"
            },
            "biyearly": {
                name: "biyearly",
                price: "$113.00",
                id: "price_1IscilJnuJLH689JwPxTj5St"
            }
        }
    },
    "prod_I4C99XnPxRSYcw": {
        name: "Female Wellness",
        descriptions: "Take a deeper look at your thyroid, vitamin D and cortisol levels.",
        id: "prod_I4C99XnPxRSYcw",
        imageUrl: "https://files.stripe.com/links/fl_test_afhi8K6ucx4xQw6pPjXWJlye",
        sku: "2867",
        prices: {
            "onetime": {
                name: "one time",
                price: "$90.00",
                id: "price_1Hp6SaJnuJLH689JMEUOCZVn"
            },
            "quarterly": {
                name: "quarterly",
                price: "$81.00",
                id: "price_1IsckMJnuJLH689Jl9kwQmn4"
            },
            "biyearly": {
                name: "biyearly",
                price: "$85.00",
                id: "price_1IsckAJnuJLH689J1mjI4dEg"
            }
        }
    },
    "prod_I4C6knyF4sGSQT": {
        name: "Male Wellness",
        descriptions: "Tired, low libido, moody or other urinary symptoms? Just get it checked out bro, from the comfort of your own home.",
        id: "prod_I4C6knyF4sGSQT",
        imageUrl: "https://files.stripe.com/links/fl_test_vonYaX7dEliTjhqx24sDMF60",
        sku: "2866",
        prices: {
            "onetime": {
                name: "one time",
                price: "$109.00",
                id: "price_1HU3iEJnuJLH689JQ7ojf1j2"
            },
            "quarterly": {
                name: "quarterly",
                price: "$98.00",
                id: "price_1IsclcJnuJLH689JZqK8mMdJ"
            },
            "biyearly": {
                name: "biyearly",
                price: "$103.00",
                id: "price_1IsclqJnuJLH689JuOQHiu4z"
            }
        }
    },
    "prod_I4C3YhuPOiYOAg": {
        name: "Overload Health Test",
        descriptions: "Ferritin, Creatinine, hs-CRP, A1B & Glucose. Whether you are a sprinter or concerned about your blood metabolism – this is your test.",
        id: "prod_I4C3YhuPOiYOAg",
        imageUrl: "https://files.stripe.com/links/fl_test_RUvyWYhRAV6h2MChpILisckc",
        sku: "2868",
        prices: {
            "onetime": {
                name: "one time",
                price: "$90.00",
                id: "price_1HU3fNJnuJLH689Jx74uQe8R"
            },
            "quarterly": {
                name: "quarterly",
                price: "$81.00",
                id: "price_1Iscn8JnuJLH689Jq5eJM1KA"
            },
            "biyearly": {
                name: "biyearly",
                price: "$85.00",
                id: "price_1IscmwJnuJLH689JYdiRvfnP"
            }
        }
    },
};